@import 'src/shared/variables.scss';

.token {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px;
  border-width: 1px;
  border-style: solid;
  border-color: $c-darkblue3;
}

.activeToken {
  @extend .token;
  background-color: $c-blue2;
  color: $c-darkblue3;
}

.incidentToken {
  @extend .token;
  background-color: $c-incident-red;
  color: $c-white;
}

.highlightActivity {
  display: inline-block;
  background-color: $c-transparent-blue !important;
  border-color: $c-blue3 !important;
  border-style: solid;
  border-width: 2px !important;
  border-radius: 15px;
}

.due-date {
  white-space: pre;
}
