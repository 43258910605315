.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.dropdown_container {
  overflow: auto;
  padding-top: 4px;
}

.dropdown_checkbox {
  padding: 0;
  margin-right: 12px;
}

.dropdown_btn_box {
  padding: 4px 12px 0 0;
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
}
