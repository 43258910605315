.formatted-text-list {
  td span {
    white-space: pre-wrap;
  }

  .th-normal {
    min-width: 100px !important;
  }
}

.formatted-text-field {
  div {
    white-space: pre-wrap !important;
  }
}

.comments-column-width {
  tbody {
    td {
      &:last-child {
        min-width: 500px !important;
      }
    }
  }
}
