@import 'shared/variables';
@import 'shared/mixins';

.application-stages__wrapper {
  display: flex;
  margin-top: 2px;

  .stage {
    width: 9px;
    height: 9px;
    background: var(--mui-palette-neutral-60);
    border-radius: 50%;
    margin-right: 2px;
    position: relative;

    &:not(:last-child)::after {
      content: '';
      height: 2px;
      width: 2px;
      right: 0;
      top: 50%;
      position: absolute;
      background: var(--mui-palette-neutral-60);
      transform: translate(100%, -50%);
    }

    &[data-status='COMPLETED'],
    &.completed {
      background: var(--mui-palette-success-main);

      &:not(:last-child)::after {
        background: var(--mui-palette-success-main);
      }
    }

    &[data-status='IN_PROGRESS'],
    &.in-progress {
      background: var(--mui-palette-info-dark);

      &:not(:last-child)::after {
        background: var(--mui-palette-info-dark);
      }
    }

    &[data-status='IN_PROGRESS_WITH_DELAY'] {
      background: $c-project-delayed;

      &:not(:last-child)::after {
        background: $c-project-delayed;
      }
    }

    &[data-status='COMPLETED_WITH_DELAY'] {
      background: $c-project-completed-with-delay;

      &:not(:last-child)::after {
        background: $c-project-completed-with-delay;
      }
    }
  }
}
