@import 'shared/variables';

body {
  .office-checkbox-buttons-group {
    transform: translateX(14px);

    .MuiFormControl-root.checkbox-form-group {
      margin-bottom: 0;
    }

    @media (min-width: 768px) {
      margin-bottom: -30px;
    }
  }
}
