@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

/* Custom variables */
// -----------------------------------------------------------------------------
//  TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//  01. Colors
//    01.01 Base colors
//    01.02 Common
//    01.03 Photo upload component
//    01.04 View employee page
//    01.05 Employee list page
//    01.07 Employee update form
//    01.08 Lightbox
//    01.09 Forms
//    01.10 Filter
//    01.11 One Stop component
//    01.12 Send Email
//    01.13 Timeline
//    01.14 Files list
//    01.15 Wysiwyg editor
//  02. Z-indexes
// -----------------------------------------------------------------------------

//  01. Colors
//    01.01 Base colors
//    Variables without prefix 'c-' are from bootstrap color variables

$c-vivid-red: #dc362e;
$c-red: var(--mui-palette-error-main);
$c-incident-red: #b94a48;
$c-incident-red2: #ffe2e2;
$c-purple: #c288ff;

$c-gray1: #f1f1f1; // color between bootstrap gray-100 and gray-200
$c-gray2: #ccc;
$c-gray3: #e8e8e8;
$c-gray4: #f5f9e9;
$c-gray5: #92a2b3;
$c-gray7: #bfccd9;
$c-gray9: #a2a2a2;
$c-gray10: #a1a1a1;
$c-gray11: #d6d6d6;
$c-darkgray1: #2f353a;
$c-darkgray2: #515157;
$c-white: var(--mui-palette-common-white);

$c-green1: #3a9d5d;
$c-green2: #e6ffed;
$c-green3: #8de792;

$c-blue1: #20a8d8;
$c-blue2: #70b8db;
$c-blue3: #155cb5;
$c-transparent-blue: rgba(194, 213, 237, 0.4);
$c-darkblue1: #181b1e;
$c-darkblue2: #2f353a;
$c-darkblue3: #143d52;

$logo-colors: (#02908d, #02758d, #0f558c, #383185, #5d3e8d, #86378b, #a82782, #bb4891, #c46ca6, #cf82b4, #e19ac2);

$old-lead-color: $c-vivid-red;

$grades-colors: (
  aplus: mix($c-vivid-red, $green, 14%),
  a: mix($c-vivid-red, $green, 29%),
  bplus: mix($c-vivid-red, $green, 43%),
  b: mix($c-vivid-red, $green, 57),
  bminus: mix($c-vivid-red, $green, 71),
  c: mix($c-vivid-red, $green, 86%),
  d: mix($c-vivid-red, $green, 100%),
);

//    01.02 Common
$invalid-input-border: var(--mui-palette-error-main);
$error-txt: var(--mui-palette-error-main);
$btn-info-txt-shadow: rgba($black, 0.4);
$entity-header-logs: #817379;

//    01.03 Photo upload component
$photo-preview-border: $gray-400;
$photo-preview-delete: var(--mui-palette-error-main);
$photo-preview-delete-disabled: $c-gray2;
$photo-preview-txt: $white;
$photo-preview-invalid: var(--mui-palette-error-main);
$photo-preview-txt-disabled: $c-gray10;
$photo-preview-loading: var(--mui-palette-primary-main); ///
$photo-upload-btn-bg: $c-gray1;
$photo-upload-btn-bg-disabled: $c-gray2;
$photo-upload-txt: $c-darkgray1;

//    01.04 View employee page
$employee-tabs-txt: $c-darkblue1;
$employee-tabs-txt-active: var(--mui-palette-primary-main);

//    01.05 Items list page
$employee-list-row-hover-bg: $c-gray1;
$new-row-bg: $c-green2;

//    01.07 Employee update form
$long-form-section-shadow-focus: rgba(var(--mui-palette-neutral-0), 0.12);

//    01.08 Lightbox
$lightbox-bg: rgba($c-darkgray2, 0.85);
$lightbox-controls: $c-gray4;
$lightbox-txt: $c-gray4;

//    01.09 Forms
$input-populated-border: $c-purple;
$input-populated-shadow: rgba($c-gray9, 0.25);

$search-autosuggest-items-bg: var(--mui-palette-common-white);
$autosuggest-items-bg: var(--mui-palette-common-surface2);
$autosuggest-suggestion-hover-bg: rgba(80, 67, 73, 0.08);
$autosuggest-notes-txt: var(--mui-palette-neutral-40);
$autosuggest-items-border: var(--mui-palette-neutralLight-main);

$btn-dropdown-bg-hover: rgba(80, 67, 73, 0.08);
$btn-dropdown-bg: var(--mui-palette-common-surface2);
$btn-dropdown-bg-disabled: rgba(#000, 0.38);
$btn-dropdown-bg-active: var(--mui-palette-common-surface4);
$btn-dropdown-remove-btn-bg: rgba(#4f5d73, 0.22);
$btn-dropdown-remove-btn-active-bg: rgba(#f2f3f4, 0.35);
$btn-dropdown-menu-text: #1f1a1c;
$btn-dropdown-menu-item-bg: rgba(80, 67, 73, 0.08);
$btn-dropdown-menu-counter-text: var(--mui-palette-common-white);
$btn-dropdown-menu-counter-bg: var(--mui-palette-primary-main);

$btn-dropdown: '.button-dropdown';

//    01.10 Filter
$filter-toggle-focus: $c-darkblue2;
$filter-applied-tip: $c-gray9;
$quick-filter-active: $c-darkblue1;

$filter-divider: #bdbdbd;

$filter-search-warning-border: #f2f3f4;

//    01.11 One Stop Component
$onestop-category-active-bg: var(--mui-palette-common-surface4);
$onestop-search-item-active-color: rgba(80, 67, 73, 0.08);
$onestop-request-border-color: $c-gray3;
$onestop-request-date: $c-gray7;

//    01.12 Send Email
$email-preview-border: $c-gray11;
$email-preview-txt-bg: $white;

//    01.13 Timeline
$timeline-entry-icon-feedback-bg: #219aae;
$timeline-entry-icon-note-bg: desaturate($orange, 10%);
$timeline-entry-icon-email-bg: #2f76ae;
$timeline-entry-icon-call-bg: #735ba0;
$timeline-entry-icon-meeting-bg: #45a667;
$timeline-entry-icon-demo-bg: #6dba7b;
$timeline-entry-icon-planning-bg: #8cb8c3;
$timeline-entry-icon-skipped-bg: #e53a2e;
$timeline-entry-additional-border: $c-gray3;
$timeline-entry-additional-text: var(--mui-palette-neutral-60);
$timeline-entry-expired-mark-bg: $c-red;

//    01.14 Files list
$files-list-border: $c-gray3;
$files-list-txt: $c-gray5;

//    01.15 Wysiwyg editor
$wysiwyg-focus-shadow: var(--mui-palette-primary-50);

//  02. Z-indexes
$z-lightbox: 1020;
$z-autosuggest-dropdown: 10;

// 03. Additional-brakpoints
$screen-l: 900px;
$screen-s: 420px;
$screen-xs: 0px;
$screen-smallMobile: 600px;
$screen-sm: 768px;
$screen-sm-max: $screen-sm - 1;
$screen-md: 990px;
$screen-md-max: $screen-md - 1;
$screen-lg: 1195px;
$screen-lg-max: $screen-lg - 1;
$screen-xl: 1440px;
$screen-xl-max: $screen-xl - 1;
$screen-xxl: 1500px;
$screen-xxxl: 1681px;

// configs
$transition-config: 0.15s ease-in-out;

// Sidebar
$sidebar-photo-container: $c-gray1;

// Quotes
$quote-border: #e5e6e7;
$quote-bg: #f4f4f4;
$quote-text: #464a4e;
$handbook-quote-border: #a2a7ab;

// Timer
$timer-bg: var(--mui-palette-error-dark);
$timer-counter-bg: var(--mui-palette-error-main);

// Horizontal menu
$horizontal-menu-active-item-text: var(--mui-palette-neutral-contrastText);
$horizontal-menu-active-item-bg: var(--mui-palette-neutralLight-60);

// Collapse
$collapse-header-text: #23282c;
$collapse-header-border: #ced2d8;
$collapse-header-bg: #fff;
$collapse-header-hover-bg: #fff;

// Qualifications
$quslifications-table-cell-bg: #f6f7f8;
$qualification-collapse-header-weight: #ced2d8;
$qualification-collapse-header-applied-weight: #2eb85c;
$qualification-collapse-header-current-weight: var(--mui-palette-info-main);

// Status
$colored-status-bg: #e6e8ec;
$colored-status: #636f83;
$colored-accepted-status-bg: #cef7d3;
$colored-accepted-status: #2da959;
$colored-in-progress-status-bg: #f3f6bb;
$colored-in-progress-status: #98a211;

$box-shadow-issues: 0 1px 2px rgba(0, 0, 0, 0.2), 0 2px 4px 2px rgba(0, 0, 0, 0.08);
$box-shadow-add-issue: 0 2px 6px 2px rgba(0, 0, 0, 0.15), 0 1px 2px 0 rgba(0, 0, 0, 0.3);

// Project dashboards
$c-project-inactive: var(--mui-palette-neutral-60);
$c-project-active-step-text: var(--mui-palette-neutral-20);
$c-project-completed: var(--mui-palette-success-20);
$c-project-completed-with-delay: var(--mui-palette-warning-20);
$c-project-delayed: var(--mui-palette-error-main);
$c-project-delayed-text: var(--mui-palette-error-40);
$c-project-in-progress: var(--mui-palette-info-dark);
