@import 'shared/variables';

.projects-dashboard__legend {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  flex-wrap: wrap;

  .projects-dashboard__legend-inner--row {
    @media (min-width: $screen-md) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .projects-dashboard__legend-item {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    margin-right: 30px;
  }

  .project-dashboard-timeline__indicator {
    flex-grow: 0;
    margin-right: 8px;

    &::after {
      content: none;
    }

    .project-dashboard-timeline__indicator-icon {
      width: 18px;
      height: 18px;
    }
  }
}
