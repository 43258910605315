@import 'src/shared/variables';

.artifact-storage-type {
  &.file {
    height: 24px;
  }

  .storage-folder-icon {
    font-size: 19px;
    color: $c-gray5;
  }

  button {
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;

    .files-list__files-image,
    .files-list__files-icon {
      width: 24px;
      height: 24px;
      display: block;
      margin: 0;
      position: relative;
      overflow: hidden;
      min-width: 24px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .files-list__files-icon {
      img {
        width: 36px;
        height: 36px;
      }
    }

    .files-list__files-name,
    .files-list__files-type {
      display: none;
    }
  }
}
