.colored-status {
  &.current-status {
    width: 120px;
  }

  &.applied-status {
    width: 100%;
    font-weight: 600;
  }
}
