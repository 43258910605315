@import 'src/shared/variables';

.process-diagram {
  border: 1px solid $black;

  @media (max-height: $screen-md-max) {
    height: 400px;
  }

  @media (min-height: $screen-md) {
    height: 430px;
  }

  @media (min-height: $screen-l) {
    height: 645px;
  }
}
