.candidate-field-with-checkbox {
  margin-bottom: 0 !important;

  .MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 0;
    .MuiCheckbox-root {
      padding: 0;
      justify-content: flex-end;
    }
  }
}
