.form-inline.additional-contact-field {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  flex-wrap: nowrap;
  word-break: normal;

  p {
    overflow: hidden;
  }

  a {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

  > div {
    overflow: hidden;
  }

  .field__wrapper {
    margin: 0;
  }
}
