@import 'shared/variables';

.floating-btns {
  padding: 16px;
  margin-right: -16px;
  margin-left: -16px;
  position: relative;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  transition: background 0.2s, padding 0.2s;
  z-index: 100;

  .btn-area {
    min-width: 200px;
    display: flex;
    align-items: flex-start;
    max-width: none;
    width: auto;
    flex-basis: auto;

    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
}

.sticky .floating-btns {
  transition: background 0.2s, padding 0.1s, opacity 0.1s;
  background: var(--mui-palette-common-surface);

  .form-errors {
    color: var(--mui-palette-error-50);
  }
}

.sticky {
  z-index: 10;
}
