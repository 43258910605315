@import 'shared/variables';

.assignee-search-field {
  margin-bottom: 0px !important;
  &.error-section {
    .form-control {
      border-color: var(--mui-palette-error-main);
    }
  }

  .form-error {
    margin-top: -20px !important;
  }
}
