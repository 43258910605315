@import 'shared/variables';

:root {
  --today-column-width: 380px;
  --today-column-gap: 40px;
}

.project-queue {
  width: var(--today-column-width);

  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-right: var(--today-column-gap);
    background-color: var(--background-color);
    border-radius: 5px;
    max-width: var(--today-column-width);

    &:last-child {
      margin-right: 0;
    }

    .add-task-button {
      text-transform: none;
      color: var(--mui-palette-primary-main);
      box-shadow: $box-shadow-add-issue;
      border-radius: 4px;
      margin: 16px 0 0;
      min-height: 40px;
      border: 0;
      width: 100%;
    }
  }

  &__header {
    padding: 5px 0 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &::after {
      content: '';
      border-radius: 4px;
      background: linear-gradient(180deg, #ffffff00 0%, #f3f3f3 43.09%);
      height: 12px;
      display: block;
      margin-top: 16px;
    }
  }

  &__title {
    line-height: 28px;
    font-size: 22px;
    font-weight: 400;
    margin: 0;
    display: inline-block;
  }

  &__subtitle {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    margin: 0;
  }

  &__issues-counter {
    display: inline-flex;
    min-width: 20px;
    height: 20px;
    padding-bottom: 0;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: 1px solid var(--mui-palette-neutral-0);
    opacity: 0.5;
    font-size: 10px;
    margin-left: 15px;
    transform: translateY(-3px);
  }
}
