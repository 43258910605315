.daterange-button-dropdown {
  .daterange-inputs {
    padding: 14px 16px 7px;
    display: flex;
    width: 100%;

    .MuiFormControl-root {
      width: 115px;
      margin: 0;

      .MuiFormHelperText-root {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 3px;
        font-size: 11px;
        line-height: 1.4;
      }
    }

    .MuiInputBase-root {
      padding: 0;

      input {
        padding: 10px 12px;
      }
    }

    & > div:last-child {
      .rdtPicker {
        right: 0;
      }
    }
  }

  .daterange-separator {
    margin: 0 16px;
    padding-top: 7px;
  }
}
