@import 'shared/variables';

.modal-window {
  .MuiDialog-paper {
    background: var(--mui-palette-background-default);
    box-shadow: 0 8px 12px 6px rgba(0, 0, 0, 0.15), 0 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    padding: 16px;

    @media (max-width: $screen-sm-max) {
      margin: 13px;
      width: calc(100% - 26px);
    }

    @media (min-width: $screen-md) {
      padding: 20px 24px;
      margin: 22px;
    }

    @media (max-width: $screen-md-max) {
      max-width: calc(100% - 26px);
    }
  }

  .MuiDialogTitle-root {
    font-size: 24px;
    line-height: 1.25;
    padding: 0 45px 23px 0;
    margin-top: 5px;
  }

  .MuiDialogActions-root {
    padding: 26px 0 0;

    .MuiButtonBase-root {
      font-size: 14px;
    }
  }

  .MuiDialogContent-root {
    padding: 0;
    overflow-y: visible;
  }

  .close-modal-icon {
    position: absolute;
    right: 7px;
    top: 19px;
    color: var(--mui-palette-grey-500);

    @media (min-width: $screen-md) {
      right: 15px;
    }
  }
}
