@import 'shared/variables';

.timer__wrapper {
  position: relative;
  border-radius: 4px;
  background-color: $timer-bg;
  overflow: hidden;
  min-width: 100px;
  min-height: 25px;

  &:has(button.Mui-disabled) {
    background-color: transparent;

    .timer__counter {
      background: transparent;
    }
  }
}

.timer__counter {
  background: $timer-counter-bg;
  display: inline-block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.button__wrapper {
  z-index: 1;
  position: relative;

  button {
    &:not(:hover):not(.Mui-disabled) {
      background-color: transparent;
      border-color: transparent;
    }
  }
}
