@import 'shared/variables';

.modifications-menu {
  display: flex;
  width: 100%;
  border-top: 1px solid $c-gray2;
  border-left: 1px solid $c-gray2;
  border-right: 1px solid $c-gray2;
}

.modification-item {
  flex: 1;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 0;
  border-right: 1px solid $c-gray2;
  border-bottom: 1px solid $c-gray2;
  background-color: $c-white;
  color: black;

  &:last-child {
    border-right: none;
  }

  &.active-item {
    border-bottom: none;
  }

  &:hover {
    background-color: $c-gray2;
  }
}

.modifications-form {
  border-left: 1px solid $c-gray2;
  border-right: 1px solid $c-gray2;
  border-bottom: 1px solid $c-gray2;
}

.modification-form {
  margin: 20px 20px 0 20px !important;
}
