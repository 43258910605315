@import 'shared/variables';

.filter-input.autosuggest-button-dropdown,
.filter-input.multiselect-button-dropdown {
  .search-filter-control-wrapper {
    position: relative;
    min-width: 275px;

    @media (max-width: $screen-sm-max) {
      font-size: 14px;
    }

    .button-dropdown__menu-item {
      &:nth-child(2) {
        margin-top: 5px;
      }

      &.isMessage {
        border-top: 1px solid $filter-search-warning-border;
        border-bottom: 1px solid $filter-search-warning-border;
        font-size: 12px;
        margin-bottom: 5px;
        margin-top: 5px;
        background: var(--mui-pallete-background-paper);
        cursor: default;
      }
    }

    div[class$='-placeholder'] {
      color: #9d999e;
    }
  }

  .search-filter-control-icon {
    position: absolute;
    top: 9px;
    left: 10px;
    font-size: 22px;
  }
}
