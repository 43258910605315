@import 'shared/variables';

.tooltip-icon {
  cursor: pointer;
  fill: var(--mui-palette-neutral-main);

  &.lead-tooltip-icon {
    font-size: 18px;
    margin-left: 5px;
    transform: translateY(3px);
  }
}

#mergeTooltip {
  margin-top: 3px;
  display: inline-block;
}

.MuiTooltip {
  &-arrow {
    color: var(--mui-palette-common-tooltipBg);
  }

  &-tooltip {
    background: var(--mui-palette-common-tooltipBg);
    font-weight: 400;
    color: var(--mui-palette-neutral-10);
    font-size: 12px;
    padding: 10px;

    ul {
      margin-bottom: 5px;
      margin-top: 5px;
      padding-left: 15px;

      li:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    p {
      margin: 0;
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    a {
      margin-left: 4px;
      font-size: 12px;
    }

    .field__wrapper {
      display: inline-flex;
    }
  }

  &-popper {
    &.tooltip-lg {
      .MuiTooltip-tooltip {
        max-width: 500px;

        @media (max-width: $screen-sm-max) {
          max-width: calc(100% - 26px);
        }
      }
    }

    &[data-popper-placement*='top'] .MuiTooltip-tooltip {
      margin-bottom: 10px;
    }

    &[data-popper-placement*='bottom'] .MuiTooltip-tooltip {
      margin-top: 10px;
    }

    &[data-popper-placement*='top'] .MuiTooltip-tooltip,
    &[data-popper-placement*='bottom'] .MuiTooltip-tooltip {
      margin-left: 13px;
      margin-right: 13px;
    }

    &[data-popper-placement*='right'] .MuiTooltip-tooltip {
      margin-left: 13px;
    }

    &[data-popper-placement*='left'] .MuiTooltip-tooltip {
      margin-right: 13px;
    }
  }
}
