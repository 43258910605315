.phone-number {
  width: 65%;
  display: inline-block;
  vertical-align: top;
}

.phone-extension {
  width: calc(35% - 16px);
  margin-left: 16px;
  display: inline-block;
}

.full-width-container {
  width: 100%;
}
