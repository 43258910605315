.copy-anchor {
  cursor: pointer;
  display: inline-flex;
  align-items: center;

  svg {
    fill: var(--mui-palette-neutral-40);
    width: 21px;
    height: 21px;

    &:hover {
      fill: var(--mui-palette-neutral-10);
    }
  }

  &.timeline-anchor,
  &.section-anchor {
    margin-left: 8px;

    svg {
      width: 22px;
      height: 22px;
      vertical-align: middle;
    }
  }

  &.section-anchor {
    svg {
      margin-top: 5px;
    }
  }

  &.one-stop-error__copy-anchor {
    line-height: inherit;
    transform: translateY(-1px);
  }
}
