@import 'src/shared/variables';

.actions-dropdown-menu.is-hidden {
  .flag-button {
    position: static !important;
  }
}

.flag-button {
  width: 100%;

  & ~ .MuiPopper-root .flag-dropdown {
    position: absolute;
    min-width: 250px;
    visibility: visible;
    transform: none !important;
    top: -3px !important;
    right: -1px !important;
    left: auto !important;
    cursor: default;
    padding-top: 5px;

    .MuiFormGroup-root {
      margin: 3px 0 10px;
    }

    .MuiList-root {
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 16px;

      .Mui-checked {
        .MuiSvgIcon-root {
          fill: var(--mui-palette-primary-main);
        }
      }

      .MuiSvgIcon-root {
        width: 20px;
        height: 20px;
        margin: 0;
      }
    }
  }
}

.MuiMenuItem-root.flag-toggler {
  padding: 0;
  background: transparent !important;
  position: static !important;

  .small-loader {
    margin: 9px 16px;
  }

  > .MuiTouchRipple-root {
    display: none;
  }
}

.inappropriate-reason {
  color: $error-txt;
  font-size: 0.76562rem;
}
