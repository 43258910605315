@import 'shared/variables';

.input-prepopulated .form-control {
  border-color: $input-populated-border;
  box-shadow: 0 0 0 0.2rem $input-populated-shadow;
}

.external-form__updated {
  h3 {
    font-size: 1rem;
  }
}

.forms-wrapper {
  margin-top: -8px;

  .radio-buttons-group {
    width: 100%;

    .radio-button-wrapper {
      width: 100%;

      input {
        margin-right: 12px;
      }
    }
  }

  .form-check {
    margin-bottom: 12px;
    width: 100%;

    &:last-of-type {
      margin-bottom: 0;
    }

    .list-group-item {
      border-radius: 5px;
      outline-color: darken($c-blue1, 5%) !important;

      &.active {
        color: $c-blue1;
        background-color: $c-white;
        border-color: $c-blue1;
      }
    }

    .custom-control-label {
      &:before,
      &:after {
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
