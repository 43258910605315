@import 'shared/variables';

.journal-entries-transfers-input {
  position: relative;

  @media (min-width: $screen-smallMobile) {
    .removable-input {
      flex-wrap: nowrap;

      &,
      &:last-of-type {
        margin-bottom: 16px;
      }

      &:not(:first-child) {
        .label__wrapper {
          display: none;
        }

        .add-info-btn {
          margin-top: 6px;
        }
      }

      .form-group {
        margin-bottom: 0;
      }

      .add-info-btn {
        width: auto;
        margin-top: 29px;
        margin-left: 20px;
      }
    }
  }

  textarea {
    resize: vertical;
    min-height: 39px;
  }

  > div > div > div > label {
    font-weight: 500;
    font-size: 16px;
    color: var(--mui-palette-primary-20);
  }

  .MuiFormHelperText-root:not(:empty) {
    margin-bottom: -6px;
  }

  .dropdown__menu {
    min-width: 300px;
  }

  @media (min-width: $screen-smallMobile) {
    #add {
      margin-top: 30px;
    }
  }
}

.journal-entries-transfer-row {
  @media (min-width: $screen-smallMobile) {
    flex-wrap: nowrap;
  }
}
