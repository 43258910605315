@import 'shared/variables';

.collapse-wrapper {
  .collapse-header-wrapper {
    @include button-variant(
      $collapse-header-bg,
      $collapse-header-bg,
      $collapse-header-hover-bg,
      $collapse-header-hover-bg,
      $collapse-header-hover-bg
    );

    display: block;
    padding: 13px 0 13px 15px;
    border-top: 1px solid $collapse-header-border;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    position: relative;
    width: 100%;
    text-align: left;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border: 2px solid $collapse-header-text;
      transform: translateY(-70%) rotate(45deg);
      transform-origin: center;
      border-top: 0;
      border-left: 0;
      position: absolute;
      transition: transform 0.3s;
      right: 13px;
      top: 49%;
    }

    &:hover,
    &:focus,
    &:active {
      border-top: 1px solid $collapse-header-border;
      border-bottom: 0;
      outline: none;
      box-shadow: none !important;
    }

    &:hover {
      &::after {
        transform: translateY(-80%) rotate(45deg) scale(1.25);
      }
    }

    &.collapse-header-wrapper--open {
      border-bottom: 0;

      &::after {
        transform: translateY(-20%) rotate(225deg);
        top: 58%;
      }

      &:hover {
        &::after {
          transform: translateY(-20%) rotate(225deg) scale(1.25);
        }
      }
    }
  }

  &:not(.collapse-wrapper--open):last-child {
    .collapse-header-wrapper {
      border-bottom: 1px solid $collapse-header-border;
    }
  }

  .collapse-title-wrapper {
    transition: padding-top 0.2s linear;

    @media (max-width: $screen-lg-max) {
      flex-wrap: wrap;
    }
  }

  .collapse-title {
    font-size: 15px;
    margin: 0;
    font-weight: 600;
    transition: font-size 0.2s linear, padding-top 0.2s linear;
    color: var(--mui-palette-neutral-dark);

    @media (max-width: $screen-lg-max) {
      width: 100%;
    }

    &.mr-auto {
      margin-right: auto;
    }
  }

  &.collapse-wrapper--open {
    .collapse-header-wrapper {
      .collapse-title-wrapper {
        @media (min-width: $screen-lg) {
          padding-top: 0.75rem;
        }
      }

      .collapse-title {
        font-size: 16px;

        @media (max-width: $screen-lg-max) {
          padding-top: 0.75rem;
        }
      }
    }
  }
}
