.multi-input__wrapper {
  &.MuiFormControl-root {
    margin-bottom: 0;
  }

  & > .Mui-error {
    display: none;
  }

  .multi-input__inner {
    display: grid;
    grid-gap: 12px;

    .label__wrapper {
      display: none;
    }
  }
}
