.candidate_specialization {
  font-weight: 400;
  font-size: 14px;
  line-height: 17.07px;
}

.candidateIconParent svg {
  /* Rewrite inline style. */
  width: 20px !important;
  height: 20px !important;
}
