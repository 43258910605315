@import 'src/shared/variables';

.datetimerange-input {
  .daterange-inputs {
    & > div > div:last-child {
      .rdtPicker {
        @media (max-width: $screen-sm-max) {
          right: 0;
        }
      }
    }
  }
}

.process-start-date__wrapper {
  .daterange-inputs {
    @media (max-width: $screen-sm-max) {
      > div {
        display: block;

        > p {
          display: none;
        }
      }
    }
  }
}
