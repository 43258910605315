@import 'shared/variables';

#{$btn-dropdown}__menu {
  &-item {
    .filter-checkbox {
      color: var(--mui-palette-text-secondary);
      font-size: 21px;
      margin-right: 6px;

      &--active {
        color: var(--mui-palette-primary-main);
      }
    }
  }
}
