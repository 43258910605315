@import 'shared/variables';

#{$btn-dropdown} {
  &__toggle {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 7px;
    opacity: 0.8;

    .filter-dropdown-counter {
      background-color: $btn-dropdown-menu-counter-bg;
      border-radius: 4px;
      padding: 4px;
      color: $btn-dropdown-menu-counter-text;
      font-size: 10px;
      line-height: 10px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-2px);
      margin-left: 4px;
      margin-right: -3px;
    }

    > span {
      white-space: nowrap;
    }

    &.button-dropdown__toggle--active {
      background-color: RGB(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-hoverOpacity));
      border: 1px solid var(--mui-palette-primary-main);
      color: var(--mui-palette-primary-main);
      opacity: 1;

      svg {
        fill: var(--mui-palette-primary-main);
      }
    }
  }

  &__menu {
    &-header {
      padding: 6px 16px 4px;
      font-weight: 600;

      &:not(:first-child) {
        margin-top: 13px;
      }

      &:not(.multiple) {
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    &-item {
      @media (max-width: $screen-sm-max) {
        min-height: 34px;
      }
    }
  }
}

.quick-filter-button,
.filter-input {
  #{$btn-dropdown}__menu {
    .react-autosuggest__suggestion,
    &-item {
      font-family: 'Open Sans', sans-serif;
      width: 100%;
      text-align: left;
      color: $btn-dropdown-menu-text;
      border: 0;
      padding: 5px 12px;
      cursor: pointer;
      white-space: nowrap;
      min-width: 200px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background: var(--mui-palette-common-background);
      min-height: 34px;
      border-radius: 0;

      @media (max-width: $screen-sm-max) {
        font-size: 14px;
      }

      &:disabled,
      &:hover,
      &:focus {
        background: $btn-dropdown-menu-item-bg;
        color: $btn-dropdown-menu-text;
        opacity: 1;
        text-decoration: none;
      }

      &:disabled {
        background: $btn-dropdown-bg-disabled !important;
      }

      &.multiple:first-child {
        margin-top: 10px;
      }
    }
  }
}

.page-content {
  .quick-filter-button,
  .filter-input {
    #{$btn-dropdown}__menu {
      .react-autosuggest__suggestion,
      &-item {
        font-size: 14px;
      }
    }
  }
}

.quick-filter-button {
  display: inline-flex;
  margin-right: 27px;
  margin-bottom: 17px;
  margin-top: -10px;

  @media (max-width: $screen-sm-max) {
    margin-top: 0;

    &:not(:last-of-type):after {
      transform: translate(13px, 12px);
    }

    > button {
      transform: translateY(6.5px);
    }
  }

  &:not(:last-of-type):after {
    content: '';
    display: inline-flex;
    width: 1px;
    min-height: 21px;
    margin-bottom: 10px;
    background-color: $filter-divider;
    transform: translate(13px, 10px);
  }

  > button {
    transform: translateY(4.5px);
  }

  #{$btn-dropdown}__menu {
    &-item {
      text-align: left;
      &:disabled {
        background: $btn-dropdown-bg-active !important;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
}
