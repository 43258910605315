@import 'shared/variables';

.lead-grade {
  font-weight: 500;

  @each $key in map-keys($grades-colors) {
    &--#{$key} {
      color: map-get($grades-colors, $key);
    }
  }
}
