.skillBadgeParent {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 5px;
}

.skillBadge {
  background-color: #5954a8;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 3px;
  line-height: 22.4px;
  border: 0px;
  font-size: 14px;
  padding: 1px 8px;
}
