.linear-progress {
  height: 5px;
  width: 100%;
  background-color: var(--mui-palette-primary-95);
  border-radius: 10px;
  display: block;
  overflow: hidden;

  .linear-progress__bar {
    height: 5px;
    background: var(--mui-palette-success-20);
    min-width: 5px;
    display: block;
  }
}
