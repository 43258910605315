@import 'shared/variables';

.view-mode-toggle {
  align-items: center;

  .MuiButtonBase-root {
    color: var(--mui-palette-neutral-10);

    &.active {
      pointer-events: none;
      background-color: var(--mui-palette-primary-95);
      color: var(--mui-palette-primary-10);
    }
  }
}
