.quick-filter-button {
  .button-dropdown__toggle {
    color: var(--mui-palette-primary-main);
    padding: 2px 10px 2px 7px;
    opacity: 1;

    svg {
      &:last-of-type {
        display: none;
      }
    }
  }

  &.applied {
    .button-dropdown__toggle {
      color: var(--mui-palette-primary-primary);
      background: var(--mui-palette-common-surface4);
      border-color: var(--mui-palette-common-surface4);

      svg {
        fill: var(--mui-palette-primary-primary);
      }
    }
  }
}
