@import 'src/shared/variables';

button.disabled {
  cursor: default !important;
}

.search-result-file-field {
  .files-list__files {
    display: inline-flex !important;

    .files-item {
      margin: 0;
      height: 100%;
      display: inline-block;
      border: 0;

      button:first-child {
        display: inline;
        padding: 0;
        border: 0;
        cursor: pointer;

        &:hover,
        &:focus {
          background: transparent;

          div,
          span {
            text-decoration: underline;
            color: var(--mui-palette-primary-main);
          }
        }

        div,
        span {
          color: var(--mui-palette-primary-main);
          margin-right: 5px;
          white-space: normal;
          overflow-wrap: anywhere;
          line-height: 1.5;
        }
      }

      .files-list__files-loader {
        transform: translateY(3px);
        height: 15px;
      }
    }

    .files-list__files-name {
      white-space: normal;
      overflow-wrap: anywhere;
    }
  }
}
