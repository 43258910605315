@import 'shared/variables';

.ck.resizable-mode .ck.ck-editor__main {
  resize: vertical;
  overflow: auto;
  height: 54.8px;
  min-height: 54.8px;
  max-height: 100vh;
}
.ck.resizable-mode .ck.ck-content.ck-editor__editable,
.ck.height-mode .ck.ck-content.ck-editor__editable {
  height: auto !important;
  min-height: 100%;
}
.ck .ck.ck-editor__main {
  border-radius: var(--ck-border-radius);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid var(--ck-color-base-border);
}
.ck .ck.ck-editor__main.ck-focused {
  border-color: var(--ck-color-focus-border);
}
.ck .ck.ck-content.ck-editor__editable {
  border: 0 !important;
}

.ck-balloon-panel {
  z-index: 1400 !important;
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
  flex-wrap: wrap;
}

.wysiwyg-container {
  width: 100%;

  border: 1px solid var(--mui-palette-common-commonInputBorder);
  border-radius: 0.25rem;

  &.is-invalid {
    border-color: $invalid-input-border;
  }

  .cke {
    width: 100% !important;
    border: none !important;

    &_top,
    &_bottom {
      background: $c-white;
      border-color: var(--mui-palette-common-commonInputBorder);
    }

    &_focus {
    }
  }
}
