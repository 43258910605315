.table-wrapper {
  &.interview-table-wrapper {
    .th-buttons {
      width: 120px;
    }
  }

  &.artifact-storage-table-wrapper {
    table.list-general {
      .th-buttons {
        width: 130px;
      }
    }
  }
}
