@import 'shared/variables';

.queues-wrapper {
  width: 100%;
  margin-left: -3px;
  overflow: auto;

  > div[data-simplebar='init'] {
    min-height: 330px;

    @media (max-width: $screen-md-max) {
      min-height: calc(100vh - 100px);
    }
  }

  .project-issues__wrapper {
    > div {
      display: grid;
      grid-auto-flow: column;
      padding: 0 10px 10px 3px;
    }
  }
}

.daily-chickin-project-allocation__wrapper {
  margin-bottom: 30px;
}

.daily-chickin__scroll-stepper {
  position: relative;

  .scroll-stepper__control {
    margin: 0;
    position: absolute;
    right: 0;
    top: -64px;

    @media (max-width: $screen-smallMobile) {
      position: static;
      margin-bottom: 20px;
    }
  }
}
