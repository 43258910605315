.candidate_avatar__wrapper {
  height: 100%;
}

.candidate_avatar__wrapper img {
  max-width: 100%;
  width: 100%;
  border-radius: 3px;
  height: 100%;
  object-fit: cover;
}
