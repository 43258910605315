@import 'shared/variables';

.sticky-menu {
  margin-top: 90px;
}

.menu-photo {
  background-color: $sidebar-photo-container;
  display: block;
  margin: 0 0 20px 10px;
  max-width: 300px;
  padding-top: 250px;
  position: relative;
  width: calc(100% - 60px);

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  i {
    position: absolute;
    font-size: 20px;
    left: calc(50% - 10px);
    top: calc(50% - 10px);
  }

  @include media-breakpoint-up(md) {
    max-width: none;
  }
}

.handbook-scrollbars,
.tabs-container {
  margin: 0;
  padding: 5px 15px;
  width: 100%;

  @include media-breakpoint-down(md) {
    overflow-x: auto;
  }

  @include media-breakpoint-up(md) {
    justify-content: space-between;
  }

  @include media-breakpoint-up(md) {
    display: block;
  }
}

.tabs-container {
  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.handbook-scrollbars {
  @media (min-width: 992px) {
    width: 285px;
    border-right: 1px solid #000;
    margin-right: 20px;
    padding: 8px 5px 8px 0;

    .tabs-container {
      padding: 0;
      padding-right: 20px;
    }

    div[data-simplebar='init'] {
      max-height: calc(100vh - 120px);
    }
  }

  @media (max-width: 991px) {
    border-bottom: 1px solid #000;
    padding-bottom: 35px;
  }
}

.sticky-sidebar-menu {
  border: 0;
  background: var(--mui-palette-background-paper);
  border-radius: 4px;
  padding: 5px;

  div[data-simplebar='init'] {
    @include media-breakpoint-up(lg) {
      max-height: calc(100vh - 120px);
    }
  }

  ul {
    padding: 11px;
    margin: 0;

    @include media-breakpoint-up(md) {
      width: 205px;
      padding: 15px;
    }
  }

  .tabs-btn {
    font-size: 14px;
    padding: 0;
    padding-left: 24px;
    margin-bottom: 12px;

    i {
      opacity: 0.7;
      left: 0;
      font-size: 17px;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.static {
      pointer-events: none;
    }

    & + .left-menu-subitems:empty:last-child {
      margin-top: -12px;
    }
  }
}

.tabs-btn {
  align-items: center;
  background: none;
  border: none;
  color: $employee-tabs-txt;
  cursor: pointer;
  display: flex;
  font-size: 15px;
  position: relative;
  margin: 0;
  margin-right: 15px;
  padding-left: 0;
  width: auto;

  @media (min-width: $screen-md) {
    margin-right: 0;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 15px;
    padding-left: 30px;
    width: 100%;

    span {
      display: inline;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      word-wrap: normal;
    }
  }

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }

  @media (min-width: $screen-xxl) {
    font-size: 18px;
    padding-left: 43px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:active,
  &:focus {
    color: $employee-tabs-txt-active;
    outline: none;
    text-decoration: none;
  }

  i {
    font-size: 25px;
    left: 0;
    position: absolute;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }

  svg {
    left: -2px;
    position: absolute;
    width: 20px;
    height: 20px;
  }
}

.tabs-btn--active {
  color: $employee-tabs-txt-active;
  text-decoration: none;
}

.left-menu-subitems {
  display: none;

  .tabs-btn--active + & {
    display: block;
  }

  &:last-child {
    .left-menu-subitem:last-child,
    .tabs-btn:last-child {
      margin-bottom: 0;
    }
  }

  .tabs-btn {
    &:hover {
      text-decoration: underline;
    }
  }
}

@media (max-width: $screen-l) {
  .left-menu-subitem {
    display: none !important;
  }
}

.left-menu-subitem,
.left-menu-subitems .tabs-btn {
  color: inherit;
  display: block;
  padding-left: 24px;
  text-transform: capitalize;
  position: relative;
  margin-bottom: 5px;

  &:first-child {
    margin-top: -5px;
  }

  &:last-child {
    margin-bottom: 20px;
  }

  &::after {
    content: '';
    position: absolute;
    width: 1px;
    height: calc(100% + 5px);
    background: var(--mui-palette-neutral-10);
    opacity: 0.35;
    left: 7px;
    top: -2px;
  }

  &.tabs-btn--active {
    color: $employee-tabs-txt-active;
    text-decoration: none;
  }
}

.sidebar-fixed {
  position: fixed;
  top: 50px;
  transition: top 0.2s, opacity 0.1s;
}

.sidebar-toggle-btn {
  width: 100%;
  background: #efedef;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-left: 10px;
  min-width: 30px;
  transition: all 0.4s ease;

  svg {
    width: 17px;
    height: 17px;
    transition: transform 0.35s ease;
  }

  @media (max-width: $screen-md-max) {
    justify-content: center;
  }
}

.collapsible-sticky-menu__wrapper {
  &.sticky-menu__wrapper {
    .tabs-btn {
      word-break: break-word;
    }

    @media (min-width: $screen-md) {
      width: 215px;
      transition: width 0.2s ease-out;

      &:hover {
        width: 215px !important;
      }

      .sticky-sidebar-menu div[data-simplebar='init'] {
        max-height: calc(100vh - 150px);
      }

      .sticky-menu__inner {
        width: 215px !important;
        position: relative;
        z-index: 100 !important;

        .collapsible-sticky-menu__content {
          transition: width 0.2s ease-out;
          width: 215px !important;
          border-radius: 4px;
        }

        .sticky-sidebar-menu ul,
        .sticky-sidebar-menu ul .tabs-btn {
          transition: padding 0.35s ease;
        }
      }

      &.minimized {
        width: 40px;

        .sticky-menu__inner {
          width: 40px !important;

          .sidebar-toggle-btn {
            svg {
              transform: rotate(180deg);
            }
          }

          &:not(:hover) {
            border-radius: 4px;

            .sticky-sidebar-menu ul {
              padding-right: 0;
              padding-left: 7px;
            }

            .collapsible-sticky-menu__content {
              overflow: hidden;
              width: 40px !important;
            }
          }

          &:hover {
            width: 215px !important;

            .collapsible-sticky-menu__content {
              box-shadow: $box-shadow-issues;
            }
          }
        }

        &:not(:hover) {
          .sidebar-toggle-btn {
            padding-right: 11px;
            padding-left: 11px;
          }
        }
      }

      .left-menu-subitems {
        width: 175px;
      }
    }

    @media (max-width: $screen-md-max) {
      width: 100%;

      .sidebar-toggle-btn {
        svg {
          transform: rotate(90deg);
        }
      }

      .sticky-sidebar-menu div[data-simplebar='init'] {
        max-height: 180px;
      }

      &.minimized {
        height: 75px;

        .sticky-menu__inner {
          border-radius: 4px;

          .sidebar-toggle-btn {
            svg {
              transform: rotate(270deg);
            }
          }
        }

        .sticky-sidebar-menu ul {
          display: flex;
          padding-top: 5px;
          padding-bottom: 5px;

          .left-menu-subitems {
            display: none;
          }
        }

        .tabs-btn {
          height: 20px;
          margin-bottom: 0;

          span {
            display: none;
          }
        }
      }
    }

    .simplebar-track.simplebar-horizontal {
      visibility: hidden !important;
    }
  }
}
