@import 'shared/variables';

:root {
  --square-size: 30px;
  --week-square-size: calc(var(--square-size) * 3 + var(--square-gap) * 2);
  --square-gap: 2px;
  --week-square-gap: 9px;
  --month-square-gap: 20px;
  --week-width: calc(var(--square-size) + var(--square-gap));
  --labels-width: 200px;
  --divider-size: 17px;
}

$c-holiday: var(--mui-palette-grey-200);
$c-leave: var(--mui-palette-info-95);
$c-working: var(--mui-palette-common-white);
$c-wrong: var(--mui-palette-error-95);
$c-right: #d3fbe3;
$c-neutral: #feffd2;
$c-cell-border: var(--mui-palette-grey-300);

.performance-heatmap {
  flex-shrink: 0;
  flex-grow: 1;
  max-width: calc(100% - var(--labels-width));

  &__inner {
    display: grid;
    grid-template-columns: auto;
    grid-gap: var(--square-gap);
  }
}

.performance-heatmap__months {
  display: grid;
  min-height: var(--square-size);
  color: var(--mui-palette-grey-500);
  font-size: 12px;

  &:first-child {
    align-items: flex-end;
  }

  li {
    white-space: nowrap;
  }
}

.performance-heatmap__wrapper {
  display: flex;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .performance-heatmap__labels {
    width: var(--labels-width);
    flex-shrink: 0;
    display: grid;
    grid-gap: var(--square-gap);
    align-items: center;
    margin-top: calc(var(--square-size) + var(--square-gap));
    margin-right: 5px;

    .divider + .divider {
      display: none;
    }

    li {
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: block;
      max-width: var(--labels-width);
    }
  }
}

.performance-heatmap__days {
  display: grid;
  grid-gap: var(--square-gap);

  &:nth-child(2) li.today::before {
    top: -2px;
    border-top-color: var(--mui-palette-primary-main);
  }

  &:nth-last-child(2) li.today::before {
    top: -7px;
    border-bottom-color: var(--mui-palette-primary-main);
  }

  &:nth-child(2):nth-last-child(2) li.today::before {
    top: -2px;
    height: calc(100% + 4px);
  }

  &.divider li {
    height: var(--divider-size);
    min-height: var(--divider-size);
    background: transparent !important;
    border-color: transparent;
    color: transparent;
  }

  &.divider + &.divider li {
    display: none;
  }

  &.performance-heatmap__days--weekly {
    grid-gap: var(--week-square-gap);
  }

  li {
    &:has(.performance-heatmap__days) {
      border: 0;
    }

    > a {
      display: block;
      width: 100%;
      height: 100%;
      min-height: calc(var(--square-size) - 2px);
      z-index: 1;
    }
  }

  .performance-heatmap__days {
    width: 100%;
    grid-gap: 0;

    li {
      &:not(:last-child) {
        border-right-width: 0;
      }

      &.performance-heatmap__day--hidden {
        background: linear-gradient(
            315deg,
            transparent 49.9%,
            var(--mui-palette-grey-300) 49.9%,
            var(--mui-palette-grey-300) 60%,
            transparent 60%
          ),
          linear-gradient(315deg, var(--mui-palette-grey-300) 10%, transparent 10%);
        background-size: 0.5em 0.5em;
        color: transparent;
      }

      &.performance-heatmap__day--empty {
        background: transparent;
        color: transparent;
      }
    }
  }
}

.performance-heatmap__legend-cell,
.performance-heatmap__days li {
  min-height: var(--square-size);
  font-size: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $c-cell-border;

  &.today {
    box-sizing: border-box;
    position: relative;

    &::before {
      box-sizing: border-box;
      content: '';
      position: absolute;
      height: calc(100% + 10px);
      width: calc(100% + 4px);
      top: -5px;
      left: -2px;
      border: 2px solid var(--mui-palette-primary-main);
      border-top-color: transparent;
      border-bottom-color: transparent;
    }
  }

  &[data-datetype='WORKING'] {
    background: $c-working;
  }

  &[data-datetype='HOLIDAY'] {
    &,
    &[data-state='NEUTRAL'],
    &[data-state='POSITIVE'],
    &[data-state='NEGATIVE'] {
      background: $c-holiday;
    }
  }

  &[data-datetype='LEAVE'] {
    &,
    &[data-state='NEUTRAL'],
    &[data-state='POSITIVE'],
    &[data-state='NEGATIVE'] {
      background: $c-leave;
    }
  }

  &[data-state='NEUTRAL'] {
    background: $c-neutral;
  }

  &[data-state='POSITIVE'] {
    background: $c-right;
  }

  &[data-state='NEGATIVE'] {
    background: $c-wrong;
  }
}

.performance-heatmap__legend {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;
  flex-wrap: wrap;

  @media (min-width: $screen-md) {
    margin-left: var(--labels-width);
  }

  &.performance-heatmap__legend--tooltip {
    margin: 0;
    display: block;

    .performance-heatmap__legend-item {
      margin-right: 3px;
      margin-bottom: 4px;

      &:last-child {
        margin-bottom: 0;
      }

      > div {
        min-width: 21px;
      }
    }

    .performance-heatmap__legend-cell {
      width: 21px;
      height: 21px;
      min-height: 21px;
    }
  }

  .performance-heatmap__legend-inner--row {
    @media (min-width: $screen-md) {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  .performance-heatmap__legend-item {
    display: flex;
    align-items: center;
    margin-bottom: var(--square-gap);
    margin-right: 30px;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row-reverse;
      min-width: calc(var(--square-size) + var(--square-gap));
      flex-shrink: 0;
      margin-right: 7px;
    }
  }

  .performance-heatmap__legend-cell {
    width: var(--square-size);
    height: var(--square-size);
    flex-grow: 0;
  }
}

.performance-heatmap__header {
  margin-bottom: 20px;

  @media (min-width: $screen-sm) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 25px;
  }

  .performance-heatmap__header-controls > div:first-child,
  .filter-item-wrapper {
    margin: 0;
    width: 140px;
    max-width: 100%;

    @media (max-width: $screen-sm-max) {
      margin-top: 15px;
    }

    .form-group {
      margin: 0;
    }

    input {
      &:hover {
        cursor: pointer;
      }
    }

    @media (min-width: $screen-sm) {
      .rdtPicker {
        right: 0;
      }
    }
  }

  .filters-reset-btn {
    display: none;
  }
}

.MuiTooltip-tooltip .performance-heatmap__cell-tooltip {
  p {
    margin: 0;

    &.mb-1 {
      margin-bottom: 10px;
    }

    .performance-heatmap__cell-tooltip-label {
      font-weight: 500;
    }
  }
}

.performance-heatmap__header-controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > div {
    margin-left: 10px;
  }

  > button {
    display: none;
  }

  .form-group {
    margin-bottom: 0;
  }

  .filter-item-wrapper {
    .form-control {
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 0.875rem;
      line-height: 1.75;
      border-color: var(--mui-palette-neutral-50);

      &:hover {
        background-color: RGBA(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-hoverOpacity));
      }
    }
  }

  button {
    font-weight: 400;
  }
}

.performance-heatmap__pagination {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 32px;
  padding-top: 15px;

  @media (min-width: $screen-md) {
    margin-left: calc(var(--labels-width) + 5px);
  }

  .MuiPagination-root {
    margin-right: 20px;

    @media (min-width: $screen-md) {
      margin-left: -10px;
    }
  }
}
