@import 'shared/variables';

.copyable-header {
  display: flex;
  align-items: center;
  margin: 8px 0 10px;

  @media (max-width: $screen-smallMobile - 1) {
    display: block;
  }

  &,
  h3 {
    font-size: 18px;
    font-weight: 400;
  }

  h3 {
    margin: 0 10px 0 0;
  }

  > .MuiSvgIcon-root {
    transform: translateY(1px);

    @media (max-width: $screen-smallMobile - 1) {
      transform: translateY(4px);
    }
  }

  > button {
    @media (max-width: $screen-smallMobile - 1) {
      line-height: 1.5;
      margin-bottom: 7px;
    }
  }
}
