@import 'shared/variables';

.step-description__wrapper {
  margin-top: 20px;

  @media (min-width: $screen-smallMobile) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }

  h4 {
    margin: 0;
    margin-bottom: 1.25em;
    font-size: inherit;
    color: inherit;
    font-weight: 600;
    line-height: 1.5;
  }

  ul {
    margin-top: 0;
    padding-left: 25px;
  }

  .field-label {
    color: var(--mui-palette-neutral-60);
    margin-right: 5px;
  }

  .countdown-timer {
    width: 125px;
    display: flex;
    justify-content: flex-end;

    p {
      margin: 0 2px;
    }

    .countdown-item .countdown-item__value {
      font-weight: 400;
    }
  }

  .countdown-item__label {
    display: none;
  }

  .step-description__attributes {
    flex-direction: column;
    display: flex;
    padding-bottom: 1em;
    margin-top: 1.5em;

    @media (min-width: $screen-smallMobile) {
      margin-top: 0;
    }
  }

  .step-description__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
    width: 100%;
    margin-left: auto;
    align-items: flex-end;

    @media (min-width: $screen-smallMobile) {
      max-width: 420px;
    }

    &:last-of-type {
      margin-bottom: auto;
    }
  }

  .step-button {
    margin-left: auto;
    margin-top: 20px;
  }
}
