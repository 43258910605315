@import 'shared/variables';

.photo-upload {
  background: $photo-upload-btn-bg;
  position: relative;
  cursor: pointer;
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(sm) {
    width: 50px;
    height: 50px;
  }

  input {
    cursor: pointer;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }

  span {
    font-size: 12px;
    margin-top: 5px;
    color: $photo-upload-txt;
    text-align: center;

    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}

.photo-upload-disabled {
  @extend .photo-upload;
  background: $photo-upload-btn-bg-disabled;
  cursor: not-allowed;
}

.upload-block {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, 100px);

  @include media-breakpoint-down(sm) {
    grid-template-columns: repeat(auto-fill, 50px);
  }

  & ~ .help-block {
    color: var(--mui-palette-text-secondary);
    font-size: 11px;
    line-height: 1.4;
    display: block;
    width: 100%;
    margin-top: 5px;
  }

  & ~ .MuiFormHelperText-root {
    margin-left: 0;
  }
}
