@import 'src/shared/variables';
@import 'src/shared/mixins';

$leftPadding: 58;
$circleDiameter: 30;
$self: '.timeline';

#{$self} {
  &__date {
    #{$self}__entry-time {
      font-size: 13px;
    }
  }
}

.timeline__entry {
  position: relative;

  .card {
    margin-bottom: 22px;
  }

  .card-body {
    min-height: 0;
    padding: 11px 16px 12px;
    white-space: pre-line;
  }

  .files-list {
    margin-top: 20px;
  }

  .timeline__type {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -5px;
    width: 6px;
    z-index: 1;
  }
}

.card-header-action {
  @include resetButton();
  line-height: 1;
}

.timeline__entry-time {
  font-size: 11px;
}
.timeline__entry-time--upd {
  color: $timeline-entry-additional-text;
}

.timeline__additional {
  border-top: 1px solid $timeline-entry-additional-border;
  color: var(--mui-palette-neutral-60);
  font-size: 13px;
  margin-top: 11px;
  padding-top: 10px;

  &:empty {
    border-top: 0;
    margin: 0;
    padding: 0;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.timeline__card-header {
  margin-bottom: 15px;

  small {
    font-size: 90%;
  }

  .timeline__card-datetime {
    margin-right: 7px;
    color: var(--mui-palette-neutral-60);
  }
}

.feedback-textarea {
  min-height: 323px; // approximate height of fields in sibling column
}

.timeline__entry-heading {
  font-size: 1.1rem;
  padding-right: 45px;
}

.timeline-expired-activity {
  position: relative;
  display: inline-block;
  top: 3px;
  right: 0;
  font-size: 22px;
  line-height: 1;
  color: $timeline-entry-expired-mark-bg;
}

.timeline__entry-description {
  white-space: initial;

  *:not(br):empty {
    display: none;
  }

  br:only-child,
  br + br {
    line-height: 0;
  }

  p {
    margin-bottom: 0.5em;
  }

  ul {
    margin: 1.5em 0;
  }
}

.feedback-timeline-footer:empty {
  display: none !important;
}

.timeline__footer {
  @media (min-width: $screen-l) {
    display: flex !important;
  }
}

.timeline .card.timeline-highlighted-entry {
  border: 2px solid var(--mui-palette-primary-main);
}

.timeline-text-container {
  p {
    margin-top: 0;
    margin-bottom: 0.25rem;
  }
}

.actions-wrapper {
  display: flex;
  align-items: center;
  position: absolute !important;
  right: 18px;
  top: 6px;
  min-height: 32px;
}

.actions-dropdown {
  position: relative;
  display: inline-flex;

  .toggle-dropdown-button {
    padding: 4px;

    .MuiSvgIcon-root {
      width: 24px;
      height: 24px;
    }
  }

  .actions-dropdown-menu {
    min-width: 200px;

    &.is-hidden {
      visibility: hidden;
    }
  }
}

.timeline__feedback-grade {
  margin-bottom: 10px;
}

.mb-0 {
  p {
    margin-bottom: 0;
  }
}
