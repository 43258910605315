.top-btns {
  .MuiButtonBase-root {
    margin-left: 12px;
    flex-shrink: 0;

    &:first-child {
      margin-left: 0;
    }
  }
}
