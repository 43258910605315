@import 'shared/variables';

.project-issues__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.project-issues__wrapper--planned {
  .project-queue {
    width: 100%;
    flex-grow: 1;

    &:empty {
      min-height: 100px;
    }

    &__wrapper {
      width: 100%;
      border: 0;
      max-width: 100%;
    }

    &__header {
      padding-bottom: 28px;

      &::after {
        display: none;
      }
    }
  }
}

.daily-checkin__wrapper {
  display: flex;
  min-height: calc(70vh - 40px);

  @media (max-width: $screen-md-max) {
    display: block;
  }

  .daily-checkin__content {
    padding: 0;
    padding-left: 32px;
    width: 100%;

    @media (max-width: $screen-md-max) {
      padding-left: 0;
    }

    &.daily-checkin__content--planned {
      padding-left: 0;
      position: relative;

      &:not(:first-child) {
        margin-top: 50px;
      }

      .simplebar-placeholder {
        width: 100% !important;
      }
    }
  }
}
