.one-click-password-form {
  display: inline-block;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 350px;

  .MuiFormControl-root {
    margin-bottom: 0;
  }

  legend {
    &.section-title {
      width: 100%;
    }

    h4 {
      font-size: 32px;
    }
  }

  input[type='password'] {
    min-width: 270px;
  }

  &--button {
    width: 100%;
    margin-top: 18px;
  }

  .long-form-section {
    border: 0;
    padding-bottom: 25px;
  }
}
