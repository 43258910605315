.EntityProfile__wrapper {
  display: flex;
  gap: 1rem;
}

.EntityProfile__leftColumn {
  max-width: 28rem;
  width: 100%;
  padding: 1rem;
  background-color: #ffffff;
  border-radius: 0.188rem;
}

.EntityProfile__rightColumn {
  width: 100%;
  border-radius: 0.188rem;
  padding: 1rem;
  background-color: #ffffff;
}
