.project-dashboard-progress {
  margin-bottom: -8px;

  .project-dashboard-timeline__item .project-dashboard-timeline__item-text {
    min-width: 160px;
    height: 64px;
    flex-shrink: 0;
    flex-grow: 0;
  }

  .project-dashboard-timeline {
    padding-bottom: 8px;
  }

  .project-dashboard-timeline__item {
    max-width: 160px;
  }
}
