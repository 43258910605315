.editable-field__wrapper {
  position: relative;
  margin-bottom: 12px;

  .form-group {
    margin-top: 0;
    margin-bottom: 0;
  }

  > .MuiFormLabel-root {
    display: block;
  }

  .editable-field__change-button {
    transform: translateY(-1px);
    margin-left: 2px;
    font-size: 12px;
    line-height: 14px;
  }

  .editable-field__field-wrapper {
    margin-top: 11px;
  }

  .editable-field-cancel {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 14px;
    z-index: 1;
  }
}
