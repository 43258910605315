@import 'shared/variables';

.daily-checkin-stepper {
  &__wrapper {
    width: 219px;
    border-right: 1px solid var(--mui-palette-neutral-0);
    padding-right: 23px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media (max-width: $screen-md-max) {
      width: 100%;
      margin-bottom: 30px;
      border-right: 0;
      padding-right: 0;
      padding-bottom: 12px;
      border-bottom: 1px solid var(--mui-palette-neutral-0);
    }

    @media (min-width: $screen-smallMobile) and (max-width: $screen-md-max) {
      .stepper {
        flex-direction: row;
        width: 100%;

        .MuiStep-root {
          width: calc(50% - 20px);
          max-width: 380px;

          &:not(:last-child) {
            position: relative;

            &::before {
              content: '';
              height: 1px;
              width: calc(100% - 55px);
              position: absolute;
              background: var(--mui-palette-StepContent-border);
              top: 17px;
              right: 0;
            }
          }
        }

        .MuiStepConnector-root {
          margin: 17px 20px 0 0;
        }

        .MuiStepConnector-line {
          border-top: 1px solid var(--mui-palette-StepContent-border);
          border-left: 0;
          min-height: 0;
          min-width: 40px;
        }

        .MuiStepLabel-label {
          display: inline-block;
          padding-right: 20px;
          background: #fff;
          z-index: 1;
          position: relative;
        }

        .MuiStepContent-root {
          border: 0;
        }
      }
    }
  }

  &__projects-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 21px;

    @media (min-width: $screen-smallMobile) and (max-width: $screen-md-max) {
      flex-direction: row;
      flex-wrap: wrap;

      button {
        margin-right: 23px;
      }
    }
  }

  &__project {
    min-width: auto;
    margin-bottom: 3px;
    text-align: left;
    line-height: 1.25;
    word-break: break-word;

    &:not(:last-child) {
      margin-bottom: 12px;
    }

    &.active {
      font-weight: 600;
      pointer-events: none;
    }
  }

  &__projects-list-wrapper {
    &--min-height {
      [data-simplebar='init'] {
        min-height: 140px;
        margin: 0 -10px;
        padding: 0 10px;
      }
    }
  }

  &__planned_info {
    margin-top: 63px;
    margin-bottom: 20px;
    width: 100%;

    @media (max-width: $screen-md-max) {
      max-width: 380px;
      margin-top: 30px;
    }

    > div {
      display: flex;
      justify-content: space-between;

      .invalid {
        color: var(--mui-palette-error-main);
      }
    }

    .form-error {
      display: block;
      font-size: 11px;
      line-height: 1.2;
      margin: 2px 0 3px;
      text-align: right;
      color: var(--mui-palette-error-main);
    }
  }
}
