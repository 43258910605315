.countdown-timer {
  display: grid;
  grid-template-columns: 1fr 5px 1fr 5px 1fr 5px 1fr 5px 1fr;
  grid-gap: 0;
  width: 155px;

  &.countdown-timer__expired {
    .countdown-item__value,
    > p {
      color: var(--mui-palette-error-40);
    }
  }

  &.countdown-timer--short {
    grid-template-columns: 1fr 5px 1fr 5px 1fr 5px 1fr;
    width: 124px;
  }

  > p {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0;
  }

  .countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .countdown-item__label {
      font-size: 9px;
      margin-top: 2px;
    }

    .countdown-item__value {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;

      span {
        margin-left: -4px;
        transform: translate(-2px, -1px);
      }
    }
  }
}
