@import 'shared/variables';

.journal-entry-total-wrapper {
  font-weight: 600;

  @media (min-width: $screen-smallMobile) {
    display: flex;
    position: absolute;
    bottom: 65px;
    right: 0;
    left: 0;
    margin-right: 46px;
    margin-left: -16px;

    > * {
      width: 25%;
      padding-left: 16px;
      position: relative;

      &:last-child {
        margin-right: 0;
      }

      &.form-error {
        padding-left: 10px;
      }
    }

    .journal-entry-total-label {
      display: none;
    }

    .form-error {
      position: absolute;
      bottom: -13px;
      left: calc(25% + 5px);
      width: 75%;
    }
  }

  @media (max-width: $screen-smallMobile - 1) {
    margin-bottom: 20px;

    .journal-entry-total-title {
      display: none;
    }
  }

  .form-error {
    display: block;
    font-size: 11px;
    line-height: 1.2;
    font-weight: 400;
    color: var(--mui-palette-error-main);
  }
}
