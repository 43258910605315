@import 'src/shared/variables';

.photo-upload {
  transition: $transition-config box-shadow;

  @media (min-width: $screen-md) {
    &.is-enter,
    &:hover {
      box-shadow: 0 0 0 2px $wysiwyg-focus-shadow;
    }
  }
}
