@import 'shared/variables';

.removable-input {
  display: flex;
  margin-bottom: 15px;

  @media (max-width: $screen-sm-max) {
    margin-bottom: 40px;
  }

  &:first-child {
    margin-top: 8px;
  }

  &:last-of-type {
    margin-bottom: 0;

    @media (max-width: $screen-sm-max) {
      margin-bottom: 25px;
    }
  }

  .form-group {
    flex-grow: 1;
    margin-top: 0;
    margin-bottom: 28px;
  }

  .react-autosuggest__container {
    flex-grow: 1;
    width: 100%;
  }

  .milestone-input {
    margin-left: 16px;

    &.milestone-input--estimated {
      max-width: 150px;
    }
  }

  .add-info-btn {
    margin-top: 29px;
    margin-left: 20px;
    min-width: 1px;
    align-self: flex-start;

    @media (max-width: $screen-sm-max) {
      margin-top: 0;
    }

    .MuiSvgIcon-root {
      width: 0.75em;
      height: 0.75em;
    }
  }

  &:not(:has(div.label__wrapper)) {
    @media (min-width: $screen-sm) {
      margin-bottom: -8px;
    }

    .MuiFormControl-root {
      @media (max-width: $screen-sm-max) {
        margin-bottom: 8px;
      }
    }

    .add-info-btn {
      margin-top: 6px;
    }

    &:not(:last-of-type) {
      @media (max-width: $screen-sm-max) {
        margin-bottom: 25px;
      }
    }
  }

  @media (max-width: $screen-sm-max) {
    flex-wrap: wrap;

    .add-info-btn {
      margin-left: 0;
      width: 100%;
    }
  }
}
