@import 'shared/variables';

.dropdown-with-title {
  select {
    option {
      &[disabled] {
        color: $c-darkgray1;
        font-weight: bold;
      }
    }
  }
}
