@import 'shared/variables';

tbody {
  .highlighted {
    background-color: $new-row-bg;
  }
}

.leads-table {
  table.list-general {
    min-width: 1500px;

    .list-btn-container {
      padding-left: 28px;
    }
    .th-buttons {
      width: 120px;
    }
  }

  table {
    td span {
      white-space: nowrap;
    }
  }
}
