@import 'shared/variables';
@import 'shared/mixins';
@import 'shared/tokens';

/*TODO split this file to separate layout and common components styles*/

body,
.form-control {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-size: 32px;
  line-height: 40px;
  font-weight: 400;
}

h2 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
}

h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
}
h4 {
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
}

h5 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

*:focus {
  outline: none;
}
.body-fixed {
  overflow: hidden;

  main {
    z-index: 1400;
  }
}

table {
  border-collapse: collapse;

  &.MuiTable-root {
    width: 100%;

    @media (max-width: #{$screen-xxl - 1}) {
      min-width: 1480px;
    }
  }
}

.one-click-table {
  table {
    &.MuiTable-root {
      @media (max-width: #{$screen-xxl - 1}) {
        min-width: 700px;
      }
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.MuiFormControl-root {
  margin-bottom: 20px;

  &.checkbox-form-group,
  &.instance-feedback__task-checkbox {
    margin-top: 0;

    &.checkbox-form-group--with-margin {
      @media (min-width: $screen-md) {
        margin-top: 22px;
      }

      @media (max-width: $screen-md-max) {
        margin-bottom: 12px;
      }
    }
  }
}

.checkbox-form-group__column {
  .MuiFormControl-root {
    margin-bottom: 0;
  }
}

.Mui-disabled {
  cursor: not-allowed;
}

.page-content {
  min-height: 70vh;
  position: relative;
  padding: 16px;

  @media (min-width: $screen-md) {
    padding: 20px 25px;
  }

  .top-btns__wrapper {
    z-index: 1;
    position: relative;

    @media (min-width: $screen-md) {
      &,
      & .top-btns {
        display: flex;
        justify-content: flex-end;
      }
    }

    .top-btns {
      display: flex;

      &:not(:empty) {
        margin-bottom: 30px;

        @media (min-width: $screen-md) {
          margin-bottom: -30px;
        }

        @media (max-width: $screen-sm) {
          margin-top: 10px;
        }
      }
    }
  }

  &.view-single-entry__wrapper {
    margin-top: -24px;
    background: transparent;

    .show-page__content-wrapper {
      @include media-breakpoint-up(md) {
        display: flex;
      }
    }

    .show-page__menu-wrapper {
      margin-bottom: 25px;
      margin-left: -16px;
      margin-right: -16px;
      flex-shrink: 0;

      @include media-breakpoint-up(md) {
        width: 215px;
        margin-left: -19px;
        margin-right: 35px;
      }

      &.show-page__menu-wrapper--desktop {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .show-page__content-inner {
      position: relative;
      margin: 0 -16px;
      border-radius: 4px;
      min-height: 50vh;
      flex-grow: 1;

      @include media-breakpoint-up(md) {
        margin: 0 -20px;
        width: calc(100% - 235px);
      }

      &:not(.show-page__content-inner--without-wrapper) {
        background: var(--mui-palette-background-paper);
        padding: 16px;

        @include media-breakpoint-up(md) {
          padding: 20px;
        }

        .section-details__wrapper:not(:last-child) {
          margin-bottom: 42px;
          padding-bottom: 20px;
          border-bottom: 1px solid var(--mui-palette-primary-95);
        }
      }

      &.show-page__content-inner--without-wrapper {
        .alerts__wrapper:not(:empty),
        .section-details__wrapper {
          background: var(--mui-palette-background-paper);
          border-radius: 4px;
          margin: 0 0 20px;
          padding: 18px 16px 0;

          @include media-breakpoint-up(md) {
            padding: 22px 20px 4px;
          }
        }
      }
    }
  }

  &:not(.onestop-page-content) .page-content {
    padding: 0;
    min-height: 50vh;
  }

  &.onestop-page-content .page-content {
    border: 1px solid var(--mui-palette-common-separator);

    &.view-single-entry__wrapper {
      margin-top: 0;
    }
  }

  .alerts__wrapper:not(:empty) {
    margin-bottom: 34px;
  }

  .show-page__header-wrapper {
    @media (min-width: $screen-md) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-btns:not(:empty) {
        margin-top: 30px;
      }
    }

    .top-btns:not(:empty) {
      flex-shrink: 0;
      margin-top: 0;
      display: flex;
      align-items: center;

      @media (max-width: $screen-md-max) {
        flex-wrap: wrap;
        margin-top: 25px;
      }
    }

    .show-page__entity-header-wrapper {
      flex-grow: 1;
      margin-right: 20px;
      display: flex;
      flex-direction: column;
    }
  }

  .show-page__breadcrumbs-wrapper {
    @media (min-width: $screen-md) {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
}

.list-general {
  table-layout: fixed;

  .th-buttons {
    width: 100px;
  }
  .list-btn-container {
    text-align: right;
    padding-right: 8px;
    padding-left: 8px;

    .MuiButtonBase-root {
      width: 100%;
      font-size: 14px;
    }
  }

  th {
    white-space: nowrap;
    width: 100px;

    & + th {
      width: auto;
    }
  }

  tbody {
    td {
      vertical-align: middle;
    }

    tr {
      &:hover {
        background-color: $employee-list-row-hover-bg;

        @include media-breakpoint-down(lg) {
          background: none;
        }
      }
    }
  }
}

.table-wrapper--md {
  table.list-general {
    @media (max-width: $screen-xxl - 1) {
      min-width: 1080px;
    }
  }
}

.section-item {
  margin-bottom: 26px;

  .list-general {
    margin-top: -23px;
    margin-bottom: 10px;
  }
}

.field__wrapper {
  > div {
    flex-grow: 1;
    word-break: break-word;
  }

  &.list-field {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .field__item-wrapper {
    &.field__item-wrapper--with-separator:not(:last-child) {
      border-bottom: 1px solid var(--mui-palette-common-separator);
      margin: 0 0 25px;
    }
  }

  &.field__wrapper--column {
    flex-direction: column;
  }

  .field-label {
    margin: 0 0 6px 0;
    flex-shrink: 0;
    color: var(--mui-palette-neutral-60);
  }
}

.view-single-entry {
  &:not(:last-child) {
    padding-bottom: 10px;
  }

  &.view-single-entry--list {
    .field__wrapper {
      margin-bottom: 5px;

      > .field-label {
        font-size: 16px;
        font-weight: 600;
        color: inherit;
      }

      ul,
      p {
        margin: 0;
        margin-top: 5px;
      }
    }
  }

  > .field__wrapper {
    margin-bottom: 26px;
  }

  .files-list__files {
    margin-bottom: -6px;
  }

  .files-list__photos {
    margin: 3px 0 -10px;
  }

  .removable-input {
    .add-info-btn {
      margin-top: 5px;
      margin-left: 20px;
      white-space: nowrap;
      text-overflow: unset;
    }
  }

  .bulleted-list-field {
    margin: 14px 0;
  }
}

.additional-contacts-input {
  .form-group {
    margin-top: 0;
  }
}

.form-group {
  .form-error {
    display: block;
  }
}

.btn-area {
  button:not(:last-child) {
    margin-right: 10px;
  }
}

.share-feedback-toggle {
  margin-bottom: 13px;

  & .MuiSwitch-track {
    &::before,
    &::after {
      font-size: 12px;
    }
    &:before {
      left: 11px !important;
    }
  }
}

.colored-status {
  display: inline-block;
  background: $colored-status-bg;
  padding: 8px 10px;
  border-radius: 2px;
  color: $colored-status;
  text-align: center;

  &.accepted-status {
    background: $colored-accepted-status-bg;
    color: $colored-accepted-status;
  }

  &.in-progress-status {
    background: $colored-in-progress-status-bg;
    color: $colored-in-progress-status;
  }
}

.visually-hidden {
  position: absolute;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  overflow: hidden;
}

.animated {
  animation-duration: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

a {
  text-decoration: none;
  color: var(--mui-palette-primary-main);

  &:hover {
    text-decoration: underline;
  }
}

.dialog-header-right-link {
  margin: 4px 0;
}

.dropdown-menu__wrapper {
  margin-top: 6px;
  border-radius: 4px;
  overflow: initial;
  min-width: 200px;

  & .MuiMenu-list {
    padding: 4px 0;
  }
}

@include table-row-variant(
  'danger',
  var(--mui-palette-error-90),
  var(--mui-palette-error-80),
  var(--mui-palette-error-80)
);
@include table-row-variant('info', var(--mui-palette-info-95), var(--mui-palette-info-90), var(--mui-palette-info-90));
@include table-row-variant(
  'secondary',
  var(--mui-palette-neutral-90),
  var(--mui-palette-neutral-80),
  var(--mui-palette-neutral-80)
);
@include table-row-variant(
  'delete',
  var(--mui-palette-common-deletedTableRow),
  var(--mui-palette-common-tableRowHover)
);

.c-title {
  color: var(--mui-palette-primary-20);
}

input[type='search'] {
  -webkit-appearance: textfield;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--mui-palette-common-inputColor);
  background-color: var(--mui-palette-common-white);
  border: 1px solid var(--mui-palette-common-commonInputBorder);
  border-radius: 4px !important;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &:hover {
    border-color: var(--mui-palette-neutral-main);
  }

  &:focus {
    border-color: var(--mui-palette-primary-main);
    box-shadow: 0 0 0 1px var(--mui-palette-primary-main);
  }

  &.is-invalid {
    border-color: var(--mui-palette-error-main);

    &:focus {
      border-color: var(--mui-palette-error-main);
      box-shadow: 0 0 0 1px var(--mui-palette-error-main);
    }
  }

  &:disabled {
    cursor: not-allowed;
    border-color: var(--mui-palette-common-commonInputBorder);
    opacity: 0.38;
    background-color: var(--mui-palette-common-inputDisabledBackground);
  }
}

.rdt.is-invalid {
  .form-control {
    border-color: var(--mui-palette-error-main);

    &:focus {
      border-color: var(--mui-palette-error-main);
      box-shadow: 0 0 0 1px var(--mui-palette-neutral-90);
    }
  }
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.breadcrumbs__wrapper {
  margin-bottom: 26px;
}

.entity-header__wrapper {
  margin-bottom: 32px;
}

[type='button']:not(:disabled),
[type='reset']:not(:disabled),
[type='submit']:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.custom-badge {
  &:first-child {
    margin-left: 0;
  }
}

.required-section {
  color: var(--mui-palette-error-main);
}
