@import 'src/shared/variables';

.handbook-pages {
  flex-direction: column;
  width: 100%;
  flex: 0 0 auto;

  @include media-breakpoint-down(md) {
    padding: 0;
  }

  .sticky-menu {
    margin-bottom: -75px;
  }

  &.nav-tabs {
    border: none;
    list-style: none;
    width: 100%;

    @include media-breakpoint-down(lg) {
      margin-bottom: 30px;
    }

    a {
      display: block;
      line-height: 19px;
      position: relative;
      border: none;
      border-radius: 0;
      background-color: transparent;
      width: 100%;
      text-align: left;
      outline: none;
      padding: 3px 0 !important;
      color: #181b1e;

      &.active,
      &.active:hover,
      &.active:focus {
        color: var(--mui-palette-primary-main);
        text-decoration: none;
      }

      &:hover {
        color: initial;
        text-decoration: underline;
      }
    }

    ul {
      list-style: revert;
      width: 100%;
      padding-left: 0 !important;
      margin-bottom: 0 !important;

      &:first-child {
        padding: 10px 7px 10px 30px !important;
      }

      &.first-list {
        padding-left: 20px !important;
      }

      li {
        margin-left: 20px;
      }
    }

    .nav-item {
      margin-left: 0 !important;
    }
  }

  .tabs-container {
    display: block;

    h1 {
      display: none;
    }

    h2 {
      font-size: 19px;
      margin-top: 20px;
      font-weight: 400;
      color: var(--mui-palette-primary-20);

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}
