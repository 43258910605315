.formatted-text-list {
  td span {
    white-space: pre-wrap;
  }

  .th-normal {
    min-width: 100px !important;
  }
}

.formatted-text-field {
  div {
    white-space: pre-wrap !important;
  }
}

.qualification-evidences {
  padding: 0;
  list-style: none;

  li:not(:last-child) {
    margin-bottom: 6px;
  }
}

.syberry-qualification-model {
  .table-pagination-wrapper {
    display: none;
  }

  .table-responsive {
    margin-bottom: 15px;

    tr:last-child td {
      border-bottom: 0;
    }
  }
}
