.filter-item-wrapper {
  display: inline-flex;
  margin-right: 12px;
  margin-bottom: 12px;

  .input-loader {
    transform: translateY(5px);
  }
}

.add-filter-button__wrapper {
  display: inline-flex;
  margin-top: -5px;
  margin-bottom: 16px;
  margin-right: 12px;

  > button {
    transform: translateY(5px);
    border: 0 !important;
    padding-top: 3px;
    padding-bottom: 3px;
    min-height: 31px;

    > svg:last-of-type {
      display: none;
    }
  }
}
