.candidate-application-form {
  @media (min-width: 600px) and (max-width: 1000px) {
    .MuiGrid-item.MuiGrid-grid-md-6 {
      flex-basis: 50%;
      -webkit-box-flex: 0;
      flex-grow: 0;
      max-width: 50%;
    }

    .removable-input {
      display: flex;
      flex-wrap: nowrap;
      margin-bottom: 0;
    }

    .removable-input .add-info-btn {
      align-self: flex-start;
      margin-left: 16px;
      margin-top: 29px;
      min-width: 1px;
      max-width: 30px;
      padding: 4px;
    }

    .removable-input:last-of-type {
      margin-bottom: 0;
    }

    .photo-upload,
    .photo-upload-disabled {
      height: 70px;
      width: 150px;
    }

    .photo-preview {
      height: 70px;
      width: 70px;
    }
  }
}
