@import 'src/shared/variables';

table {
  tr {
    &.active-row {
      outline: 2px solid $c-blue1;

      td {
        &:first-of-type,
        &:last-of-type {
          position: relative;

          &:before {
            content: '';
            height: calc(100% + 1px);
            width: 2px;
            position: absolute;
            top: -1px;
          }
        }

        &:first-of-type {
          &:before {
            border-left: 2px solid $c-blue1;
            left: 0;
          }
        }

        &:last-of-type {
          &:before {
            border-right: 2px solid $c-blue1;
            right: 0;
          }
        }
      }
    }
  }
}
