@import 'src/shared/variables';
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.evaluation-criterion-form {
  span {
    font-weight: 500;
  }
}

.evaluation-criterion-form-separator,
.evaluation-criterion-form-separator-expanded {
  border-color: $c-gray3;
  border-width: 0;
  border-bottom-width: 1px;
}

.evaluation-criterion-form-separator {
  margin-top: -13px;
}

.evaluation-criterion-form-separator-expanded {
  margin-top: 0px;
  margin-bottom: -9px;
}
