@import 'shared/variables';

.edit-employee-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: -20px;

  @media (max-width: $screen-md-max) {
    .prepopulate-container {
      .btn {
        height: 28px;
        width: 120px;
        font-size: 0.76563rem;
        line-height: 1.5;
        padding: 0.25rem 0.5rem;
        border-radius: 0.2rem;
      }
    }
  }
}
