.filters-reset-btn {
  display: inline-flex;
  margin-bottom: 12px;
  vertical-align: inherit;

  > button {
    padding: 3px 7px;
    min-height: 31px;
    min-width: 30px;
  }
}
