@import 'shared/variables';

button.plan-project-issue-button {
  min-width: 24px;
  height: 24px;
  border-radius: 100px;
  box-shadow: none !important;
  margin-right: 12px;
  padding: 2px;
  flex-grow: 0;
  flex-shrink: 0;

  &.MuiButton-outlined {
    border-color: var(--mui-palette-neutral-0);
    color: var(--mui-palette-neutral-0);
  }

  &.MuiButton-contained {
    background: var(--mui-palette-primary-main);
    color: var(--mui-palette-common-white);
  }

  &.Mui-disabled {
    opacity: 0.35;
    cursor: not-allowed;
    pointer-events: initial;
  }

  svg {
    width: 18px;
    height: 18px;
  }
}

.project-issue {
  font-size: 14px;
  margin-bottom: 8px;
  border-radius: 4px;
  z-index: 1;
  background: var(--mui-palette-common-surface1);
  box-shadow: $box-shadow-issues;
  position: relative;

  .project-issue__title {
    flex-grow: 1;
    word-break: break-word;
  }

  .project-issue-title__wrapper {
    border-radius: 4px;
    background-color: white;
    padding: 10px;
    display: flex;
    align-items: center;

    &:hover {
      .move-to-top-button {
        display: initial;
        width: 40px;
        height: 40px;
        flex-shrink: 0;
      }
    }

    .move-to-top-button {
      display: none;
    }
  }

  .project-issue__priority {
    border-radius: 100px;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    flex-grow: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    margin-left: 8px;
    background: var(--mui-palette-grey-200);

    &.urgent {
      background: var(--mui-palette-error-main);
    }

    &.high {
      background: var(--mui-palette-error-70);
    }

    &.medium {
      background: var(--mui-palette-warning-main);
    }

    &.low {
      background: var(--mui-palette-success-main);
    }
  }

  .project-issue-details__wrapper {
    border-radius: 0 0 4px 4px;
    padding: 14px 18px;
  }

  .project-issue__details {
    display: flex;
    justify-content: space-between;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .project-issue__days-left {
      color: var(--mui-palette-error-60);
      font-weight: 600;
      flex-shrink: 0;
      font-size: 12px;
      margin-left: 16px;
      max-width: 100px;
      text-align: right;
      padding-top: 2px;
    }

    .project-issue__job-number {
      color: var(--mui-palette-neutral-50);
      font-size: 12px;
    }

    .project-issue__description {
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      max-height: 85px;
      word-break: break-word;
    }
  }

  .project-issue__more-button {
    width: 100%;
    text-transform: none;
    color: var(--mui-palette-primary-main);
    font-size: 12px;
    background: var(--mui-palette-common-surface3) !important;

    &:hover {
      background: var(--mui-palette-common-surface5) !important;
    }
  }

  .project-issue__project-name {
    color: var(--mui-palette-neutral-50);
    font-size: 12px;
    font-weight: 600;
  }
}

.project-issue__progress {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.project-issue__wrapper--new {
  position: relative;

  .project-issue__new-label {
    display: block;
    background: linear-gradient(135deg, transparent 50%, var(--mui-palette-success-main) 50%);
    width: 16px;
    height: 16px;
    border-bottom-right-radius: 4px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.issue-context-menu {
  .MuiList-root {
    min-width: 230px;
  }
}
