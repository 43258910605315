@import 'shared/variables';

.project-dashboard-timeline__indicator {
  display: flex;
  align-items: center;

  &::after {
    content: '';
    border-radius: 1px;
    background: $c-project-inactive;
    height: 4px;
    flex: 1 1 auto;
    margin-left: 4px;
  }

  &[data-status='IN_PROGRESS'] {
    &::after,
    .project-dashboard-timeline__indicator-icon {
      background: $c-project-in-progress;
    }
  }

  &[data-status='IN_PROGRESS_WITH_DELAY'] {
    &::after,
    .project-dashboard-timeline__indicator-icon {
      background: $c-project-delayed;
    }
  }

  &[data-status='COMPLETED'] {
    &::after,
    .project-dashboard-timeline__indicator-icon {
      background: $c-project-completed;
    }
  }

  &[data-status='COMPLETED_WITH_DELAY'] {
    &::after,
    .project-dashboard-timeline__indicator-icon {
      background: $c-project-completed-with-delay;
    }
  }

  .project-dashboard-timeline__indicator-icon {
    width: 12px;
    height: 12px;
    background: $c-project-inactive;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;

    svg {
      fill: var(--mui-palette-common-white);
      width: 100%;
      height: 100%;
    }
  }
}
