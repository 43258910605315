.avatarSection__wrapper {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, auto);
  min-height: 100px;
  gap: 6px 10px;
}

.avatar_image_wrapper {
  grid-column: span 2 / span 2;
  grid-row: span 3 / span 3;
  min-width: 40px;
}

.avatar_image {
  width: 100%;
  object-fit: cover;
  min-width: 40px;
}

.user_fullname {
  grid-column: span 4 / span 4;
  grid-column-start: 3;

  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.175rem;
  text-align: left;
  color: #5954a8;
}

.contact_link_icons {
  grid-column: span 2 / span 2;
  grid-column-start: 7;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-end;
  gap: 0.375rem;
  height: 100%;
}

.languages {
  grid-column: span 4 / span 4;
  grid-column-start: 3;
  grid-row-start: 2;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.recruting_status {
  grid-column: span 2 / span 2;
  grid-column-start: 7;
  grid-row-start: 2;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 10px;
}

.resume_link {
  grid-column: span 6 / span 6;
  grid-column-start: 3;
  grid-row-start: 3;

  &:empty {
    display: none;
  }
}
