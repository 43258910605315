.radio-control-wrapper {
  &.radio-control-wrapper__column {
    .label__wrapper {
      margin-bottom: 4px;
    }
  }

  .label__wrapper {
    margin-bottom: -4px;
  }

  .MuiFormGroup-root {
    min-height: calc(1.4375em + 33px);
  }

  > .MuiFormHelperText-root:not(:empty) {
    margin-top: -8px;
  }
}
