.form-buttons-wrapper {
  display: flex;
  flex-wrap: wrap;

  .btn-area {
    flex-shrink: 0;
  }

  .form-errors {
    transition: color 0.2s;
    color: var(--mui-palette-error-main);
  }

  .error-section-link {
    display: block;
  }
}
