.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-flex {
  display: flex;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-md-nowrap {
  @media (min-width: $screen-md) {
    flex-wrap: nowrap;
  }
}

.align-items-center {
  align-items: center;
}

.w-100 {
  width: 100%;
}

.pr-3,
.px-3 {
  padding-right: 1rem;
}

.pl-3 {
  padding-left: 1rem;
}

.pl-4 {
  padding-left: 1.25rem;
}

.pl-5 {
  padding-left: 1.5rem;
}

.m-0 {
  margin: 0;
}

.mr-5,
.mx-5 {
  margin-right: 3rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.justify-content-md-end {
  @media (min-width: $screen-md) {
    justify-content: flex-end;
  }
}

.position-relative {
  position: relative;
}

.font-italic {
  font-style: italic;
}

.border-0 {
  border: 0;
}

.position-static {
  position: static;
}
