.process-tooltip {
  margin-left: 0.5em;
  font-size: 1.5em;
  cursor: pointer;
  transform: translateY(3px);
}

.tooltip-wrapper > * {
  display: inline-block;
}
