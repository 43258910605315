.project-dashboard-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.project-dashboard-timer-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -3px;
  width: 180px;

  b,
  button {
    font-size: 12px;
    padding: 0;
    white-space: nowrap;
    min-height: 21px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > span {
    margin: 0 4px;
    line-height: 21px;
  }
}

.project-dashboard-timer__label {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 1px;
  text-align: center;
  white-space: nowrap;

  &:first-of-type {
    margin-top: 0;
  }
}
