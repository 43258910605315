@import 'src/shared/variables';

.long-form-section {
  background: var(--mui-palette-background-paper);
  border: 1px solid var(--mui-palette-common-separator);
  border-radius: 4px;
  box-shadow: var(--mui-shadows-0);
  margin: 0 0 30px;
  padding: 22px 20px 4px;
  position: relative;
  transition: box-shadow 0.2s;

  &:focus-within {
    box-shadow: var(--mui-shadows-5);
  }

  &:last-of-type {
    margin-bottom: 15px;
  }

  legend {
    color: var(--mui-palette-primary-20);
    margin-bottom: 20px;
    padding: 0;

    h4 {
      & ~ .copy-anchor {
        transform: translateY(5px);
      }
    }
  }
}

.long-form-section-min-width {
  min-width: auto;
}

.MuiDialogContent-root {
  .long-form-section {
    border: 0;
    margin: 0 0 20px;
  }
}
