.long-text-field {
  white-space: pre-wrap;
}

.long-text-form-btn {
  .form-control {
    border: none !important;
    text-align: left !important;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 40px;
    max-width: 100%;
    cursor: pointer;
  }
}
