.artifact-storage-breadcrumbs {
  .MuiBreadcrumbs-ol {
    padding: 0;
    border-bottom: 0;
    margin-top: 20px;
    margin-bottom: 10px;

    .breadcrumb-item {
      color: var(--mui-palette-neutral-50);
      min-width: 1px;
      text-decoration: none;
      font-weight: 400;

      &.active {
        color: var(--mui-palette-primary-20);
      }
    }

    .breadcrumb-item + .breadcrumb-item {
      padding-left: 8px !important;
    }
  }
}
