@import 'src/shared/variables';

.mail-preview {
  border: 1px solid $email-preview-border;
  padding: 40px;
  position: relative;
  margin: 20px 0;

  &:before {
    content: 'Preview';
    position: absolute;
    top: -1.1em;
    background-color: $email-preview-txt-bg;
    display: block;
    padding: 5px 10px;
    font-weight: 700;
  }
}
