.manual-table tr,
.manual-table td {
  border: 1px solid #e4e7ea;
}

.manual-table td {
  padding: 8px;
}

.manual-table {
  margin-top: 10px;
}

.manual-container {
  margin-top: 40px;
}
