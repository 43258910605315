.scroll-stepper__wrapper {
  .scroll-stepper__control {
    background: transparent;
    border: 1px solid var(--mui-palette-neutral-50);
    border-radius: 4px;
    padding: 0;
    max-width: 140px;
    margin-bottom: 20px;

    button {
      min-width: auto;
      background: transparent !important;

      &:not(.Mui-disabled) {
        color: var(--mui-palette-primart-main);
      }

      &:hover {
        background-color: RGB(
          var(--mui-palette-action-activeChannel) / var(--mui-palette-action-hoverOpacity)
        ) !important;
      }

      .MuiTouchRipple-root {
        display: none;
      }
    }
  }
}
