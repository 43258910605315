.employee_dashboard-loading {
  .loading {
    width: 15px;
  }
}

.employee_dashboard_collapse_wrapper {
  margin-bottom: 20px;
  & > .collapse-header-wrapper {
    border-radius: 3px;
    padding: 0;
    &::after {
      left: 10px;
      width: 6px;
      height: 6px;
      right: initial;
      top: 49% !important;
    }

    &:hover {
      &::after {
        transform: translateY(-80%) rotate(45deg) scale(1.1) !important;
      }
    }

    &.collapse-header-wrapper--open {
      border-bottom: 0;

      &::after {
        transform: translateY(-20%) rotate(225deg) !important;
        top: 49% !important;
      }

      &:hover {
        &::after {
          transform: translateY(-20%) rotate(225deg) scale(1.1) !important;
        }
      }
    }
  }
}

.color-by-zone-SAFE {
  & > .collapse-header-wrapper {
    color: var(--mui-palette-border-success);
    background: var(--mui-palette-background-success);
    border: 1px solid var(--mui-palette-border-success) !important;

    &::after {
      border: 1px solid var(--mui-palette-border-success);
      border-top: 0;
      border-left: 0;
    }
  }
}
.color-by-zone-MONITORING {
  & > .collapse-header-wrapper {
    color: var(--mui-palette-border-warning);
    background: var(--mui-palette-background-warning);
    border: 1px solid var(--mui-palette-border-warning) !important;

    &::after {
      border: 1px solid var(--mui-palette-border-warning);
      border-top: 0;
      border-left: 0;
    }
  }
}
.color-by-zone-CRITICAL {
  & > .collapse-header-wrapper {
    color: var(--mui-palette-border-error);
    background: var(--mui-palette-background-error);
    border: 1px solid var(--mui-palette-border-error) !important;

    &::after {
      border: 1px solid var(--mui-palette-border-error);
      border-top: 0;
      border-left: 0;
    }
  }
}
.color-by-zone-ESCALATION {
  & > .collapse-header-wrapper {
    color: var(--mui-palette-border-error);
    background: var(--mui-palette-background-hardError);
    border: 1px solid var(--mui-palette-border-error) !important;

    &::after {
      border: 1px solid var(--mui-palette-border-error);
      border-top: 0;
      border-left: 0;
    }
  }
}
