@import 'shared/variables';

body {
  .dropdown__menu {
    z-index: 10;
    box-shadow: var(--mui-shadows-2);

    &-list {
      background: $btn-dropdown-bg;
    }

    .dropdown__option {
      padding: 5px 12px;
      color: $btn-dropdown-menu-text;

      &:hover,
      &--is-focused {
        background: $btn-dropdown-bg-hover;
      }

      &--is-selected {
        background: $btn-dropdown-bg-active;
      }
      &--is-disabled {
        opacity: 0.5;
      }
    }
  }

  .dropdown__menu-portal {
    z-index: 1400;
  }
}

.dropdown-container {
  $select: '.dropdown';
  width: 100%;

  &.is-invalid {
    #{$select} {
      &__control {
        &,
        &:hover {
          border-color: var(--mui-palette-error-main);
        }

        &--is-focused {
          box-shadow: inset 0 0 0 1px var(--mui-palette-error-main);

          &,
          &:hover {
            border-color: var(--mui-palette-error-main);
          }

          & ~ .dropdown__label {
            color: var(--mui-palette-error-main);
          }
        }
      }
    }
  }

  &.dropdown--is-disabled {
    #{$select} {
      &__label {
        color: var(--mui-palette-text-disabled);
      }

      &__single-value {
        color: var(--mui-palette-text-disabled);
      }

      &__control {
        cursor: not-allowed;
        border-color: var(--mui-palette-action-disabled);
        opacity: 0.38;
        background-color: var(--mui-palette-common-inputDisabledBackground);
      }
    }
  }

  #{$select} {
    &__control {
      padding: 4px 10px;
      background: inherit;
      outline: none;
      box-shadow: none;
      border-color: var(--mui-palette-common-commonInputBorder);
      transition:
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out !important;
      background-color: var(--mui-palette-background-paper);

      &:hover {
        border-color: var(--mui-palette-neutral-main);

        #{$select}__clear-indicator {
          opacity: 1;
        }
      }

      &--is-focused {
        outline: 0;
        box-shadow: 0 0 0 1px var(--mui-palette-primary-main);

        #{$select}__placeholder {
          opacity: 1;
        }

        &,
        &:hover {
          border-color: var(--mui-palette-primary-main);
        }

        & ~ .dropdown__label {
          color: var(--mui-palette-primary-main);
        }
      }

      &--menu-is-open {
        #{$select}__indicator {
          transform: rotate(180deg);
        }
      }
    }

    &__value-container {
      padding: 0 4px 0 1px;
      min-height: 2em;
    }

    &__single-value {
      margin: 0;
    }

    &__input {
      transform: translateX(-2px);
    }

    &__multi-value {
      margin-top: 3px;
    }

    &__menu-notice--no-options {
      color: $autosuggest-notes-txt;
    }
  }
}

.finance-account-dropdown {
  .dropdown__option.dropdown__option--is-disabled {
    color: var(--mui-palette-neutral-60);
    cursor: default;
    display: block;
    background: transparent !important;
  }
}
