.edit-competency-status-btn {
  padding: 5px;
  margin: 0 0 0 7px;
  min-width: 20px;
  flex-shrink: 0;
  align-self: center;

  svg {
    width: 20px;
    height: 20px;
  }

  &.edit-evaluation-mode-btn {
    padding: 4px;
    transform: translateY(-2px);

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
