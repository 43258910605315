@import 'shared/variables';

.payroll-reports-table-wrapper {
  table.MuiTable-root {
    min-width: 1080px;

    th:nth-last-child(2) {
      width: 120px;
    }

    th:nth-last-child(3) {
      width: 100px;
    }

    th:last-child {
      width: 140px;
    }
  }
}

.payroll-approve-table {
  table.MuiTable-root {
    min-width: 520px;

    th:first-child {
      width: auto;
    }

    tbody tr:last-child td {
      font-weight: 600;
    }
  }
}
