@import 'shared/variables';

.planned-delivered {
  font-style: italic;
  color: var(--mui-palette-neutral-40);
}

.planned-undelivered {
  font-style: italic;
  color: var(--mui-palette-error-main);
}

.unplanned-delivered {
  font-style: italic;
  color: var(--mui-palette-neutral-40);
}

.unplanned-undelivered {
  font-style: italic;
  color: var(--mui-palette-error-main);
}

.formatted-text-list {
  td span {
    white-space: pre-wrap;
  }

  .th-normal {
    min-width: 100px !important;
  }
}
