/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}

.rdt .form-group.form-control {
  margin: 0;
}

.rdtPicker {
  display: none;
  position: absolute;
  width: 286px;
  padding: 10px 12px 12px;
  margin-top: 5px;
  z-index: 99999 !important;
  background: var(--mui-palette-common-surface3);
  box-shadow: none;
  border: 0;
  border-radius: 4px;
}
.rdtPicker table {
  border-spacing: 3px;
  border-collapse: separate;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
  border-radius: 4px;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 32px;
  width: 34px;
  padding: 0;
  border: 0;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay {
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  color: var(--mui-palette-neutral-10);
  border-radius: 4px;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: rgba(32, 26, 27, 0.08);
  cursor: pointer;
}
.rdtPicker td span.rdtOld,
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #50434970;
}
.rdtPicker td.rdtToday {
  color: var(--mui-palette-primary-main);
  background: transparent;
  box-shadow: inset 0 0 0 1px var(--mui-palette-primary-main);
}
.rdtPicker td.rdtToday:focus,
.rdtPicker td.rdtToday:hover {
  background: var(--mui-palette-common-surface2);
}

.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: var(--mui-palette-primary-main);
  color: var(--mui-palette-common-white);
}

.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover,
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  opacity: 0.38;
  color: var(--mui-palette-neutral-10);
  cursor: not-allowed;
}

.rdtPicker .dow {
  font-weight: 600;
  font-size: 12px;
  height: 45px;
  padding-top: 10px;
}

.rdtPicker th.rdtSwitch {
  width: 82px;
  font-size: 14px;
  font-weight: 600;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 30px;
  font-weight: 400;
  line-height: 34px;
  width: 34px;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  transform: translateY(-3px);
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  opacity: 0.38;
  color: var(--mui-palette-neutral-10);
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
  border-radius: 4px;
}
.rdtPicker thead tr:first-child th:hover {
  background: rgba(32, 26, 27, 0.08);
}

.rdtPicker tfoot {
  transform: translateY(12px);
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 34px;
  width: 25%;
  cursor: pointer;
  border-radius: 4px;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: rgba(31, 26, 28, 0.08);
}

.rdtCounters {
  display: flex;
  justify-content: center;
  padding-top: 7px;
  padding-bottom: 2px;
}

.rdtCounter {
  height: 120px;
  width: 44px;
  padding: 0 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rdtCounterSeparator {
  line-height: 120px;
  width: 8px;
  font-size: 18px;
}

.rdtCounter .rdtBtn {
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  display: block;
  border-radius: 4px;
  font-size: 0;
  position: relative;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Chrome/Safari/Opera */
  -khtml-user-select: none; /* Konqueror */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:before {
  content: '';
  border: 2px solid var(--mui-palette-text-primary);
  border-right: 0;
  border-top: 0;
  transform: translate(-50%, calc(-50% - 2px)) rotate(-45deg);
  transform-origin: center;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 9px;
  height: 9px;
}
.rdtCounter .rdtBtn:first-child:before {
  transform: translate(-50%, calc(-50% + 2px)) rotate(135deg);
}
.rdtCounter .rdtBtn:hover {
  background: rgba(32, 26, 27, 0.08);
}
.rdtCounter .rdtCount {
  font-size: 18px;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 18px;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.rdtPicker .rdtTimezone {
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  margin-top: 16px;
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .rdtPicker {
    width: 250px;
    padding: 5px 8px 8px;
    font-size: 14px;
  }

  .rdtPicker td,
  .rdtPicker th {
    height: 30px;
  }

  .rdtPicker .dow {
    height: 32px;
    padding-top: 10px;
  }

  .rdtPicker .rdtTimezone {
    font-size: 12px;
  }

  .rdtPicker th.rdtNext,
  .rdtPicker th.rdtPrev {
    line-height: 30px;
    width: 30px;
  }
  .rdtCounter {
    width: 50px;
    padding: 0 10px;
  }

  .rdtCounter .rdtBtn {
    height: 30px;
    line-height: 30px;
  }
  .rdtCounter .rdtBtn:before {
    line-height: 30px;
    width: 30px;
  }
}

.rdtPicker .rdtTodayBtn {
  display: block;
  margin: 16px 6px 5px;
  padding: 4px 15px;
  color: var(--mui-palette-primary-main);
  font-size: 14px;
}

.rdtPicker .rdtTodayBtn:hover {
  background: transparent;
  text-decoration: underline;
}
