@import 'shared/variables';

.list-top-btns {
  min-height: 44px;
  margin-top: -5px;
  margin-bottom: 12px;

  .list-top-btns__filters {
    display: flex;
    align-items: center;

    @media (max-width: $screen-sm-max) {
      order: 1;
      width: 100%;
    }

    & .react-autosuggest__container {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      @media (max-width: $screen-sm-max) {
        width: 100%;
      }
    }

    & .MuiFormControl-root {
      margin: 0;

      & ~ .react-autosuggest__suggestions-container {
        transform: translateY(7px);
        background: $search-autosuggest-items-bg;
      }
    }

    .react-autosuggest__input {
      @media (max-width: $screen-sm-max) {
        min-width: 180px !important;
        width: 100%;
      }
    }
  }

  .list-top-btns__actions {
    margin-left: auto;

    @media (max-width: $screen-sm - 1) {
      order: 0;
      margin-left: 0;
      margin-bottom: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      a {
        margin: 0;
      }
    }
  }

  .dropdown-toggle,
  .list-filter-toggle,
  .create-btn {
    color: #000;
    border-radius: 2px;
    padding: 8px 14px;
    background: transparent;

    &:hover {
      background-color: RGBA(var(--mui-palette-primary-mainChannel) / var(--mui-palette-action-hoverOpacity));
    }
  }

  .list-filter-toggle {
    &.open {
      background: var(--mui-palette-common-surface4);
    }
  }
}
