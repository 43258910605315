@import 'shared/variables';
@import 'shared/mixins';

$leftPadding: 58;

.timeline {
  position: relative;
  list-style: none;
  margin: 0 0 20px;
  padding: 0;

  blockquote {
    font-style: italic;
    padding: 11px 25px 11px 17px;
    margin: 0 0 20px;
    transform: translateY(10px);
    border-left: 6px solid $quote-border;
    background-color: $quote-bg;
    color: $quote-text;
  }

  .timeline__entry {
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: initial;
      border: 0;
      box-shadow: $box-shadow-issues;
      border-radius: 0.25rem;

      &:not(.border-success) {
        border-left: 4px solid #c8ced3;
      }

      &.border-success {
        border: 1px solid var(--mui-palette-success-main) !important;

        .card-body {
          padding-bottom: 20px;
        }

        .MuiButtonBase-root {
          font-size: 14px;
        }
      }
    }

    // feedback timeline entry
    &.feedback {
      .card:not(.border-success) {
        border-left-color: $timeline-entry-icon-feedback-bg !important;
      }
    }

    // note timeline entry
    &.note {
      .card:not(.border-success) {
        border-left-color: $timeline-entry-icon-note-bg !important;
      }
    }

    // email timeline entry
    &.candidate_sender,
    &.email {
      .card:not(.border-success) {
        border-left-color: $timeline-entry-icon-email-bg !important;
      }
    }

    // call timeline entry
    &.call {
      .card:not(.border-success) {
        border-left-color: $timeline-entry-icon-call-bg !important;
      }
    }

    // meeting timeline entry
    &.meeting {
      .card:not(.border-success) {
        border-left-color: $timeline-entry-icon-meeting-bg !important;
      }
    }

    // demo timeline entry
    &.demo {
      .card:not(.border-success) {
        border-left-color: $timeline-entry-icon-demo-bg !important;
      }
    }

    // planning timeline entry
    &.planning {
      .card:not(.border-success) {
        border-left-color: $timeline-entry-icon-planning-bg !important;
      }
    }

    // skipped timeline entry
    &.skipped {
      .card:not(.border-success) {
        border-left-color: $timeline-entry-icon-skipped-bg !important;
      }
    }

    &.syberry_sender,
    &.resume {
      .card:not(.border-success) {
        border-left-color: #6c757d;
      }
    }
  }
}

.timeline__page_top_block {
  margin-bottom: 18px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: $screen-sm - 1) {
    display: block;
  }

  .top-btns {
    @media (max-width: $screen-sm - 1) {
      margin-top: 10px;
    }
  }
}
