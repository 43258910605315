@import 'src/shared/variables';

.remove-list-item {
  padding: 4px;
  min-width: 1px;

  @media (min-width: $screen-md) {
    margin-top: 16px;
  }

  @media (max-width: $screen-md) {
    margin-bottom: 16px;
    width: 100%;
  }

  .MuiSvgIcon-root {
    width: 0.75em;
    height: 0.75em;
  }
}
