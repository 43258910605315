.daily-chickin__project-allocation {
  margin-right: 40px;
  width: 100%;
  max-width: 380px;

  .project-allocation__title {
    margin-bottom: 10px;
  }

  .project-allocation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-child(2) {
      margin-bottom: 8px;
    }

    &__value {
      text-align: right;
    }
  }

  .allocation-metric {
    display: none;
  }

  .allocation-input {
    margin: 0;
    max-width: calc(100% - 10px);

    input {
      padding: 0;
      border: 0 !important;
      text-align: right;
      box-shadow: none !important;
      color: var(--mui-palette-primary-main);

      &.is-invalid {
        color: var(--mui-palette-error-main);
      }
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type='number'] {
      -moz-appearance: textfield;
    }

    .form-error {
      text-align: right;
      line-height: 1.2;
    }

    .label__wrapper {
      display: none;
    }

    &:has(input:not(:placeholder-shown)) {
      & ~ .allocation-metric {
        display: inline-block;
        color: var(--mui-palette-primary-main);
        cursor: text;
      }
    }

    &:has(input.is-invalid) {
      & ~ .allocation-metric {
        color: var(--mui-palette-error-main);
      }
    }

    .MuiFormHelperText-root.Mui-error {
      margin-right: 0;
      position: absolute;
      top: 18px;
      right: 0;
      width: 100vw;
      max-width: 225px;
    }

    input:not(:placeholder-shown) {
      ~ .MuiFormHelperText-root.Mui-error {
        margin-right: -9px;
      }
    }
  }
}

.weekly-allocations-progress__wrapper {
  position: relative;

  .weekly-allocations-progress__separator {
    width: 1px;
    height: calc(100% + 8px);
    position: absolute;
    top: -4px;
    background: var(--mui-palette-neutral-70);
  }

  .weekly-allocations-progress {
    height: 10px;
    width: 100%;
    min-width: 135px;
    background-color: var(--mui-palette-neutral-90);
    border-radius: 10px;
    max-width: 135px;

    &.orange {
      .linear-progress__bar {
        background: var(--mui-palette-warning-main);
      }
    }

    &.red {
      .linear-progress__bar {
        background: var(--mui-palette-error-60);
      }
    }

    .linear-progress__bar {
      height: 10px;
      min-width: 0;
      background: var(--mui-palette-success-20);
    }
  }
}
