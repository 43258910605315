@import 'shared/variables';

.lightbox {
  align-items: center;
  background-color: $lightbox-bg;
  display: flex;
  justify-content: center;
  height: 100vh;
  left: 0;
  opacity: 0;
  padding: 30px 70px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: $z-lightbox;
}

.lightbox--visible {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.lightbox__img {
  max-height: 100%;
  max-width: 100%;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.lightbox__img--hidden {
  opacity: 0;
}

.lightbox__prev,
.lightbox__next,
.lightbox__close {
  background: none;
  background-repeat: no-repeat;
  border: none;
  color: $lightbox-controls;
  cursor: pointer;
  height: 30px;
  position: absolute;
  text-indent: -9999px;
  width: 30px;
}

.lightbox__download {
  background: none;
  background-repeat: no-repeat;
  border: none;
  color: $lightbox-controls;
  cursor: pointer;
  height: 30px;
  position: absolute;
  width: 30px;
  font-size: 20px;
  bottom: 5px;
  right: 5px;
}

.lightbox__download:focus,
.lightbox__prev:focus,
.lightbox__next:focus,
.lightbox__close:focus {
  outline: none;
}

.lightbox__prev,
.lightbox__next {
  top: calc(50% - 20px);
}

.lightbox__prev {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1792'%3E%3Cpath fill='%23f5f9e9' d='M1427 301L896 832l531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19L429 877q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z'/%3E%3C/svg%3E%0A");
  left: 15px;
}

.lightbox__next {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1792 1792'%3E%3Cpath fill='%23f5f9e9' d='M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45L531 45q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z'/%3E%3C/svg%3E%0A");
  right: 15px;
}

.lightbox__close {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%23f5f9e9' d='M443.6 387.1L312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z'/%3E%3C/svg%3E%0A");
  height: 20px;
  right: 10px;
  top: 10px;
  width: 20px;
}

.lightbox__message {
  color: $lightbox-txt;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
