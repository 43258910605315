@import 'shared/variables';
@import 'shared/mixins';

.collapse-wrapper {
  .applied-status-weight,
  .current-status-weight,
  .apply-column {
    padding: 0 0.75rem;
    box-sizing: border-box;
    transition: width 0.3s linear;

    @media (max-width: $screen-lg-max) {
      padding-left: 0;
      margin-top: 10px;
    }

    .weight-value {
      color: $qualification-collapse-header-weight;
    }
  }

  .apply-column {
    @media (max-width: $screen-xxl - 1) {
      display: none;
    }
  }

  .applied-status-weight {
    width: 160px;
    font-weight: 600;

    .weight-value:not(.empty-value) {
      color: $qualification-collapse-header-applied-weight;
    }
  }

  .current-status-weight {
    width: 190px;

    .weight-value:not(.empty-value) {
      color: $qualification-collapse-header-current-weight;
    }
  }

  &:not(.collapse-wrapper--open) {
    .collapse-header-wrapper {
      .apply-column {
        width: 5px !important;
      }
    }
  }
}

.form-group.completeness__switch {
  margin-left: 4px;
  margin-bottom: 0;
  margin-top: 0;
}
