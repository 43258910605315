@import 'shared/variables';

.sensitive-data-plug {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mui-palette-background-paper);
  padding-bottom: 60px;
  min-height: 450px;

  .security-image {
    height: 180px;
  }
}
