.empty-level-message {
  display: inline-block;
  padding: 5px 15px 26px;
  font-style: italic;
}

.syberry-qualification-model {
  .empty-level-message {
    padding-top: 15px;
    padding-bottom: 30px;
  }
}
