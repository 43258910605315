.candidate_specialization {
  font-weight: 400;
  font-size: 14px;
  line-height: 17.07px;
}

.stageStep {
  background-color: #069e56;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  color: #ffffff;
}

.stageStep_inactive {
  background-color: #d9d9d9;
}

.stageStep_past {
  background-color: #069e56;
  opacity: 0.5;
}

.stageStep_active {
  background-color: #069e56;
  opacity: 1;
}
