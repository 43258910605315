@import 'shared/variables';

.skip-checkin-button {
  margin-top: auto;
}

.skip-checkin__message {
  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding-left: 20px;
  }
}

.skip-checkin__buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;

  &:last-child {
    margin-top: 30px;
  }
}
