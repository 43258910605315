@import 'shared/variables';

.data-row-toggle {
  transform: translateX(-7px);
  margin-left: -4px;
}

.data-cell-wrapper {
  padding-left: calc(38px * var(--level));
  display: flex;
  align-items: center;
}
