@import 'src/shared/variables';

.leave-duration-layout {
  margin-bottom: 11px;
  @media (min-width: $screen-xl) {
    margin-top: 22px;
  }
}

.leave-duration-layout__loading {
  margin-bottom: 11px;

  @media (min-width: $screen-xl) {
    margin-top: 25px;
  }
}
