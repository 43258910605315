.kpi-value-layout {
  padding: 16px;
}

.kpi-value-layout__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.kpi-value-layout__controls {
  display: flex;
  align-items: center;
}
