@import 'shared/variables';

.family-member-input {
  @media (min-width: $screen-sm) {
    margin-top: -10px;
    margin-bottom: 5px;
  }

  @media (min-width: $screen-xl) {
    .removable-input {
      .full-width {
        > div {
          flex-basis: 33.3333%;
          max-width: 33.3333%;
        }
      }
    }
  }

  .full-width {
    flex-grow: 1;
  }
}
