@import 'shared/variables';
@import 'shared/mixins';

.employee-competencies-table {
  th {
    font-weight: 600;
  }

  th:nth-child(3),
  td:nth-child(3) {
    background: $quslifications-table-cell-bg;
  }

  tr:hover {
    background: none !important;
  }

  .list-general.table {
    @media (max-width: $screen-xxl - 1) {
      min-width: 1080px;
    }
  }
}

.qualification__controls {
  margin-left: auto;

  &:not(:empty) {
    margin-top: 1rem;

    @media (min-width: $screen-sm) {
      margin-top: 0;
    }

    @media (min-width: $screen-lg) {
      border-right: 1px solid $c-gray2;
      padding-right: 18px;
      margin-right: 18px;
    }
  }
}

.single-entry-info-block {
  &:last-child {
    border-bottom: 0 !important;
  }
}
