@import 'shared/variables';

.project-dashboard-timeline {
  display: flex;
  width: auto;

  .project-dashboard-timeline__item {
    display: flex;
    flex-direction: column;
    margin-left: 4px;

    &:first-child {
      margin-left: 0;
    }

    &[data-status='IN_PROGRESS'] {
      h3,
      .project-dashboard-timeline__item-text p {
        color: $c-project-in-progress;
      }
    }

    &[data-status='IN_PROGRESS_WITH_DELAY'] {
      h3,
      .project-dashboard-timeline__item-text p {
        color: $c-project-delayed;
      }
    }

    &[data-status='COMPLETED'] {
      h3,
      .project-dashboard-timeline__item-text p {
        color: $c-project-completed;
      }
    }

    &[data-status='COMPLETED_WITH_DELAY'] {
      h3,
      .project-dashboard-timeline__item-text p {
        color: $c-project-completed-with-delay;
      }
    }

    &[data-status='COMPLETED'],
    &[data-status='COMPLETED_WITH_DELAY'],
    &[data-status='IN_PROGRESS_WITH_DELAY'] {
      .project-dashboard-timeline__indicator {
        .project-dashboard-timeline__indicator-icon {
          width: 18px;
          height: 18px;
          margin-top: -3px;
          margin-bottom: -3px;
        }
      }
    }

    h3 {
      margin-bottom: auto;
      color: $c-project-inactive;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.25;
      overflow-wrap: normal;

      @media (max-width: 2764px) {
        white-space: pre-wrap;
      }
    }
  }

  .project-dashboard-timeline__item-text {
    display: flex;
    flex: 1 1 auto;
    width: auto;
    flex-direction: column;
    min-width: 120px;
    padding-right: 5px;
    margin-bottom: 12px;

    p {
      margin: 0;
      color: $c-project-inactive;
      white-space: nowrap;

      &:first-of-type {
        margin-top: 9px;
      }
    }
  }

  .project-dashboard-timeline__item-additional-text {
    &:not(:empty) {
      background: #f5f5f5;
      margin-top: 10px;
      padding: 7px 5px;
      font-size: 13px;
    }
  }
}
