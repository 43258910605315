.to-top-btn {
  display: block;
  position: fixed;
  bottom: 57px;
  right: 40px;
  z-index: 11;

  &.to-top-btn--hidden {
    display: none;
    opacity: 0;
  }

  .MuiButton-root {
    min-width: 0;
    padding: 5px;

    &:not(:hover) {
      background: var(--mui-palette-primary-50);
    }
  }
}
