.employee-address-header {
  display: block;
  font-size: 1.5em;
  font-weight: 400;
  margin-bottom: 10px;

  button {
    float: right;

    @media (max-width: 1710px) {
      float: none;
      margin: 12px 0 5px;
    }
  }
}
