.link {
  cursor: pointer;
  color: #6963a0;

  &:hover {
    text-decoration: underline;
  }
}

.contact-modal > .MuiDialog-container > .MuiDialog-paper {
  max-width: 650px;
}

.spinner {
  display: inline-block;
  position: relative;
  width: 1em;
  height: 1em;
  margin-left: 11px;

  @media (min-width: 990px) {
    width: 17px;
    height: 17px;
  }
}
