.search-candidate-control {
  margin-bottom: 0 !important;
  color: black;
  min-width: 242px;

  .position-relative .react-autosuggest__container .form-control {
    font-size: 12px;
    padding-left: 25px;
  }
}
