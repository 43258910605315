@import 'shared/variables';

.context-names-container {
  margin-top: 10px;
}

.variable-context-name {
  display: inline-block;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.active-variable-context {
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  font-weight: 400 !important;
  text-decoration: none !important;
  &:hover {
    color: $c-white !important;
  }
}

.variables-wrapper {
  min-height: 400px;
}

.clicked-history-item {
  background-color: $new-row-bg;
}
