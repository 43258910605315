@import 'shared/variables';

.photo-preview {
  width: 100px;
  height: 100px;
  position: relative;
  border: 1px solid $photo-preview-border;

  @include media-breakpoint-down(sm) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .photo-preview__btn {
    cursor: pointer;
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: $photo-preview-delete;
    border: 0;
    color: $photo-preview-txt;
    border-radius: 50%;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .photo-preview__btn-disabled {
    @extend .photo-preview__btn;
    background-color: $photo-preview-delete-disabled;
    color: $photo-preview-txt-disabled;
  }
}

.photo-preview--invalid {
  border-color: $photo-preview-invalid;
}

.photo-preview--uploading {
  border-color: transparent;
  transition: border-color 0.2s;

  img {
    opacity: 0.3;
    transition: opacity 0.2s;
  }

  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid $photo-preview-loading;
    border-right: 2px solid transparent;
    animation: spinner 0.6s linear infinite;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
