.access-log tr,
.access-log td {
  border: 1px solid #e4e7ea;
}

.access-log td {
  padding: 8px;
}

.access-log {
  margin-top: 10px;
}

.access-log-container {
  margin-top: 40px;
}
