.leaves-list-wrapper {
  th:last-child {
    width: 0;
  }

  &.leaves-list-wrapper--with-compensation-btn {
    th:last-child {
      width: 176px;
    }
  }
}
