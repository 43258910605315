@import 'src/shared/variables';

.on-time {
  color: #2da959 !important;
}

.with-delay {
  color: #f1a924 !important;
}

.no-data {
  color: $c-vivid-red !important;
}
