@import 'simplebar-react/dist/simplebar.min.css';

body {
  .scrollbars__wrapper {
    padding: 2px;
  }

  .simplebar-track {
    border-radius: 4px;
    transition: background 0.2s 0.5s linear;

    &.simplebar-vertical {
      width: 5px;
    }

    &.simplebar-horizontal {
      height: 5px;
    }

    .simplebar-scrollbar:before {
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: var(--mui-palette-common-scrollbar);
      transition: opacity 0.2s 0.5s linear;
    }
  }

  div[data-simplebar='init'] {
    &:hover {
      .simplebar-track {
        background: var(--mui-palette-common-scrollbarTrack);
      }

      .simplebar-scrollbar:before {
        background: var(--mui-palette-common-scrollbar);
        opacity: 1;
      }
    }
  }
}
