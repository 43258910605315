@import 'src/shared/variables.scss';

.collapsible {
  overflow: hidden;
  transition: max-height 0.2s;
  position: relative;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 50px;
    opacity: 0;
    background-image: linear-gradient(0, $c-white, rgba($c-white, 0));
    transition: opacity 0.3s;
    z-index: -1;
  }

  &.show-blur {
    &::after {
      opacity: 0.9;
      z-index: inherit;
    }
  }
}
