@import 'shared/variables';
@import 'shared/mixins';

%autosuggest-container {
  background: $autosuggest-items-bg;
  left: 0;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  right: 0;
  z-index: $z-autosuggest-dropdown;
  padding: 4px 0;
}

.react-autosuggest__container {
  position: relative;

  &--open {
    .react-autosuggest__no-result,
    .react-autosuggest__warning-message {
      display: block;
    }
  }

  input.MuiInputBase-input {
    font-size: 14px;
    padding: 10px 14px;
  }
}

.react-autosuggest__suggestions-container {
  left: 0;
  position: absolute;
  right: 0;
  z-index: $z-autosuggest-dropdown;
}

.react-autosuggest__suggestions-container--open {
  @extend %autosuggest-container;

  box-shadow: var(--mui-shadows-3);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.react-autosuggest__suggestions-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 12px;
  font-size: 1em;

  &:hover {
    background-color: $autosuggest-suggestion-hover-bg;
  }
}

.react-autosuggest__no-result,
.react-autosuggest__warning-message {
  @extend %autosuggest-container;

  color: $autosuggest-notes-txt;
  padding: 10px 12px;
  text-align: center;
}

.react-autosuggest__no-result {
  box-shadow: var(--mui-shadows-3);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: $autosuggest-items-bg;
}

.react-autosuggest__warning-message {
  border-bottom: 1px solid $autosuggest-items-border;
  display: none;
  font-size: 13px;
  position: static;
}

.autosuggest__loader {
  position: absolute;
  top: 11px;
  right: 10px;

  ~ .react-autosuggest__container {
    input {
      padding-right: 40px;
    }
  }
}
