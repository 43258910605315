@import 'shared/variables';

.performance-heatmap__sticky-header {
  .performance-heatmap-sticky-header__background {
    display: none;
  }

  &.sticky {
    margin-top: 64px;

    @media (max-width: $screen-sm-max) {
      margin-top: 60px;
    }

    .performance-heatmap-sticky-header__background,
    &::before {
      position: absolute;
      top: 0;
      height: 36px;
      background: var(--mui-palette-background-paper);
    }

    &::before {
      content: '';
      left: calc(0px - var(--labels-width) - 5px);
      width: calc(var(--labels-width) + 5px);
    }

    .performance-heatmap-sticky-header__background {
      display: block;
      left: 0;
      right: 0;
    }

    .performance-heatmap-sticky-header__content {
      overflow: hidden;
      position: relative;
      z-index: 1;
    }
  }
}
