@import 'shared/variables';

.single-entry-info-block {
  .relations-heading {
    margin-bottom: 32px;

    .top-btns {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      flex-shrink: 0;
      align-items: flex-start;

      @media (max-width: $screen-sm - 1) {
        margin-top: 20px;
        justify-content: flex-start;
      }
    }
  }

  .list-top-btns {
    margin-bottom: 17px;
    min-height: auto;
    margin-top: 0;
  }
}
