.projects-dashboard-table {
  .list-general {
    table-layout: auto;
    min-width: 1080px;

    th:nth-child(2),
    td:nth-child(2) {
      padding-right: 18px;
      padding-left: 12px;
    }
  }
}

tbody {
  .highlighted {
    background-color: #ff00001a;
  }
}
