@import 'shared/variables';

.circular-progress-with-label {
  position: relative;
  display: inline-flex;
  width: 196px;
  max-width: 300px;

  &__background {
    color: var(--mui-palette-neutral-95);
  }

  &__progress {
    position: absolute;
  }

  &__label {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: $screen-sm) {
    margin-right: 80px;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-md-max) {
    margin-right: 64px;
  }

  @media (max-width: $screen-sm-max) {
    margin-bottom: 26px;
  }
}
