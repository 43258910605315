.candidate_timeline_page_top {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
}

.candidate_timeline_header {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  padding-right: 3px;
  font-size: 12px;
  height: 20px;
  align-items: center;
}

.employee_action {
  padding-right: 16px;
}

.candidate_timeline_datetime {
  margin-right: 7px;
  color: var(--mui-palette-neutral-60);
}

.candidate_timeline_actions_wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-right: 4px;
}

.candidate_timeline_actions_dropdown {
  position: relative;
  display: inline-flex;

  .toggle_dropdown_button {
    padding: 1px;
    margin-right: 12px;

    .MuiSvgIcon_root {
      width: 18px;
      height: 18px;
    }
  }
}

.timeline_anchor {
  svg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
  }
}

.candidate_timeline_filter_icon {
  margin-bottom: 18px;
}

.candidate_timeline {
  position: relative;

  blockquote {
    font-style: italic;
    padding: 11px 25px 11px 17px;
    margin: 0 0 20px;
    transform: translateY(10px);
    background-color: var(--mui-palette-neutral-40);
  }
}

.full_width_candidate_offer_layout {
  width: 100%;
}
