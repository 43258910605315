@import 'shared/variables';

$step-border-width: 1px;
$c-step-border: #eaeaea;
$c-inactive-step-bg: #f5f5f5;
$c-active-step-bg: var(--mui-palette-primary-main);
$c-active-step-hover-bg: var(--mui-palette-primary-dark);
$c-completed-step-bg: var(--mui-palette-success-main);
$c-completed-step-hover-bg: var(--mui-palette-success-dark);
$c-failed-step-bg: var(--mui-palette-error-main);
$c-failed-step-hover-bg: var(--mui-palette-error-dark);
$c-postponed-step-bg: var(--mui-palette-warning-main);
$c-postponed-step-hover-bg: var(--mui-palette-warning-dark);
$c-hover-step-bg: #eaeaea;
$active-step-border-width: 2px;
$c-active-step-border: #828282;
$c-control-bg: #fff;

.opportunity-stepper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .opportunity-steps__control {
    border: 1px solid $c-step-border;
    padding: 5px 0;
    border-radius: 0;
    z-index: 1;
    transition: none;

    &:last-child {
      transform: rotate(180deg);
    }

    &:before,
    &:after {
      content: '';
      left: -1px;
      position: absolute;
      height: calc(50% + 1px);
      width: 100%;
      border-left: $step-border-width solid $c-step-border;
    }

    &:before {
      border-top: $step-border-width solid $c-step-border;
      transform-origin: 0 0;
      transform: skewX(-30deg);
      top: -1px;
      box-shadow: inset 0 10px 0 10px $c-control-bg, inset -6px 10px 0 10px $c-control-bg;
    }
    &:after {
      border-bottom: $step-border-width solid $c-step-border;
      transform-origin: 0 100%;
      transform: skewX(30deg);
      bottom: -1px;
      box-shadow: inset 0 -10px 0 10px $c-control-bg, inset -6px -10px 0 10px $c-control-bg;
    }

    svg {
      z-index: 2;
      transform: translateX(-3px) scale(1.2);
      width: 20px;
    }

    &:hover {
      background: $c-hover-step-bg;

      &:before {
        box-shadow: inset 0 10px 0 10px $c-hover-step-bg, inset -6px 10px 0 10px $c-hover-step-bg;
      }
      &:after {
        box-shadow: inset 0 -10px 0 10px $c-hover-step-bg, inset -6px -10px 0 10px $c-hover-step-bg;
      }
    }
  }

  .opportunity-steps {
    flex-shrink: 1;

    .simplebar-track {
      display: none !important;
    }
  }

  .steps__wrapper {
    display: flex;
  }

  .stepper_step-label {
    z-index: 2;
    position: relative;
    padding: 10px 15px;
    margin: 0;
    transform: translateX(-3px);
    width: 100%;
    white-space: nowrap;

    svg {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(calc(-50% - 1px));
    }
  }

  .stepper_step {
    padding: 0;
    background: $c-inactive-step-bg;
    position: relative;
    margin-left: 22px;
    line-height: 16px;
    text-align: center;
    z-index: 1;
    min-width: 135px;
    transition: none;
    flex-shrink: 1;
    color: var(--mui-palette-text-primary);
    border-radius: 0;

    &:before,
    &:after {
      content: '';
      left: -15px;
      position: absolute;
      height: 50%;
      width: calc(100% + 16px);
      border-left: $step-border-width solid $c-step-border;
      border-right: $step-border-width solid $c-step-border;
    }
    &:before {
      border-top: $step-border-width solid $c-step-border;
      transform-origin: 0 0;
      transform: skewX(30deg);
      top: 0;
      box-shadow: inset 0 8px 0 8px $c-inactive-step-bg, inset -6px 8px 0 8px $c-inactive-step-bg;
    }
    &:after {
      border-bottom: $step-border-width solid $c-step-border;
      transform-origin: 0 100%;
      transform: skewX(-30deg);
      bottom: 0;
      box-shadow: inset 0 -8px 0 8px $c-inactive-step-bg, inset -6px -8px 0 8px $c-inactive-step-bg;
    }

    /*First and Last styles*/
    &:first-of-type {
      left: -15px;
      box-shadow: 15px 0 0 0 $c-inactive-step-bg;
      border-left: $step-border-width solid $c-step-border;
      margin-right: -1px;
      margin-left: 15px;

      .stepper_step-label {
        transform: translateX(7px);
      }
    }
    &:first-of-type:before,
    &:first-of-type:after {
      left: -1px;
      border-left: 0 !important;
    }
    &:first-of-type:hover {
      box-shadow: 15px 0 0 0 $c-hover-step-bg;
    }

    &:last-of-type {
      left: 0;
      box-shadow: inset -1px 0 0 0 $c-step-border, inset 0 -1px 0 0 $c-step-border, inset 0 1px 0 0 $c-step-border;
      border: 0;
      padding-left: 15px;

      .stepper_step-label {
        transform: translateX(-13px);

        svg {
          right: -7px;
        }
      }
    }
    &:last-of-type:before,
    &:last-of-type:after {
      left: -15px;
      border-right: 0 !important;
      width: 100%;
    }
    &:last-of-type:hover {
      background: $c-hover-step-bg;
    }

    &:hover {
      background: $c-hover-step-bg;

      &::before {
        box-shadow: inset 0 16px 0 16px $c-hover-step-bg, inset -6px 8px 0 8px $c-hover-step-bg;
      }
      &::after {
        box-shadow: inset 0 -16px 0 16px $c-hover-step-bg, inset -6px -8px 0 8px $c-hover-step-bg;
      }
    }

    &.stepper_step--completed {
      background: $c-completed-step-bg;
      color: #fff;

      &:before {
        box-shadow: inset 0 8px 0 8px $c-completed-step-bg, inset -6px 8px 0 8px $c-completed-step-bg;
      }
      &:after {
        box-shadow: inset 0 -8px 0 8px $c-completed-step-bg, inset -6px -8px 0 8px $c-completed-step-bg;
      }
      &:first-of-type {
        box-shadow: 15px 0 0 0 $c-completed-step-bg;
      }

      &:hover {
        background: $c-completed-step-hover-bg;

        &::before {
          box-shadow: inset 0 16px 0 16px $c-completed-step-hover-bg, inset -6px 8px 0 8px $c-completed-step-hover-bg;
        }
        &::after {
          box-shadow: inset 0 -16px 0 16px $c-completed-step-hover-bg, inset -6px -8px 0 8px $c-completed-step-hover-bg;
        }
      }
    }

    &.stepper_step--failed {
      background: $c-failed-step-bg;
      color: #fff;

      &:before {
        box-shadow: inset 0 8px 0 8px $c-failed-step-bg, inset -6px 8px 0 8px $c-failed-step-bg;
      }
      &:after {
        box-shadow: inset 0 -8px 0 8px $c-failed-step-bg, inset -6px -8px 0 8px $c-failed-step-bg;
      }
      &:first-of-type {
        box-shadow: 15px 0 0 0 $c-failed-step-bg;
      }

      &:hover {
        background: $c-failed-step-hover-bg;

        &::before {
          box-shadow: inset 0 16px 0 16px $c-failed-step-hover-bg, inset -6px 8px 0 8px $c-failed-step-hover-bg;
        }
        &::after {
          box-shadow: inset 0 -16px 0 16px $c-failed-step-hover-bg, inset -6px -8px 0 8px $c-failed-step-hover-bg;
        }
      }
    }

    &.stepper_step--postponed {
      background: $c-postponed-step-bg;
      color: #fff;

      &:before {
        box-shadow: inset 0 8px 0 8px $c-postponed-step-bg, inset -6px 8px 0 8px $c-postponed-step-bg;
      }
      &:after {
        box-shadow: inset 0 -8px 0 8px $c-postponed-step-bg, inset -6px -8px 0 8px $c-postponed-step-bg;
      }
      &:first-of-type {
        box-shadow: 15px 0 0 0 $c-postponed-step-bg;
      }

      &:hover {
        background: $c-postponed-step-hover-bg;

        &::before {
          box-shadow: inset 0 16px 0 16px $c-postponed-step-hover-bg, inset -6px 8px 0 8px $c-postponed-step-hover-bg;
        }
        &::after {
          box-shadow: inset 0 -16px 0 16px $c-postponed-step-hover-bg, inset -6px -8px 0 8px $c-postponed-step-hover-bg;
        }
      }
    }

    &.stepper_step--current {
      background: $c-active-step-bg;
      color: #fff;

      &:before {
        box-shadow: inset 0 8px 0 8px $c-active-step-bg, inset -6px 8px 0 8px $c-active-step-bg;
      }
      &:after {
        box-shadow: inset 0 -8px 0 8px $c-active-step-bg, inset -6px -8px 0 8px $c-active-step-bg;
      }
      &:first-of-type {
        box-shadow: 15px 0 0 0 $c-active-step-bg;
      }

      &:hover {
        background: $c-active-step-hover-bg;

        &::before {
          box-shadow: inset 0 16px 0 16px $c-active-step-hover-bg, inset -6px 8px 0 8px $c-active-step-hover-bg;
        }
        &::after {
          box-shadow: inset 0 -16px 0 16px $c-active-step-hover-bg, inset -6px -8px 0 8px $c-active-step-hover-bg;
        }
      }
    }

    &.stepper_step--active {
      pointer-events: none;

      & {
        &:before,
        &:after {
          border-left: $active-step-border-width solid $c-active-step-border;
          border-right: $active-step-border-width solid $c-active-step-border;
        }

        &:before {
          border-top: $active-step-border-width solid $c-active-step-border;
        }

        &:after {
          border-bottom: $active-step-border-width solid $c-active-step-border;
        }

        &:first-of-type {
          border-left: $active-step-border-width solid $c-active-step-border;
        }

        &:last-of-type {
          box-shadow: inset -2px 0 0 0 $c-active-step-border, inset 0 -2px 0 0 $c-active-step-border,
            inset 0 2px 0 0 $c-active-step-border;
        }
      }
    }
  }
}
