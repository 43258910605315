.wysiwyg-wrapper {
  position: relative;

  &__loader {
    position: absolute;
    bottom: 0;
    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    background: rgba(0, 0, 0, 0.1);

    pointer-events: none;
  }

  &__cancel {
    position: absolute;
    bottom: -40px;
    z-index: 101;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__cancel button {
    padding: 2px 20px;
  }
}
