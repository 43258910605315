@import 'shared/variables';

.mapping-metrics-wrapper {
  width: 100%;

  .mapping-metrics-inner {
    flex-grow: 1;
  }

  @media (min-width: $screen-sm) {
    display: flex;
    align-items: flex-start;
  }
}
