.single-entry-info-block {
  &.relation-list {
    text-align: left;

    thead {
      th {
        .sort-btn {
          margin: 0;
        }
      }
    }

    .alert {
      text-align: center;
    }
  }
}
