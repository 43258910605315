.feedback-spinner {
  position: relative;
  width: 1em;
  height: 1em;

  @media (min-width: 990px) {
    margin-right: 11px;
    width: 17px;
    height: 17px;
  }
}
