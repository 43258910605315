@import 'shared/variables';

.handbook-search-item {
  color: inherit;
  display: block;
  padding: 15px 16px;
  margin-bottom: 12px;

  &:hover,
  &:focus,
  &.focus {
    background-color: rgba(80, 67, 73, 0.08);
    color: inherit;
    outline: none;
    text-decoration: none;
  }

  p {
    margin: 0;
    padding: 0;
  }

  > p {
    display: inline;
  }

  > i {
    color: #817379;
    font-size: 12px;
    font-style: normal;
    white-space: nowrap;
  }
}

.handbook-search-no-results {
  margin-top: 30px;
  text-align: center;
  font-weight: 400;
}

.handbook-search-wrapper {
  @media (max-width: 991px) {
    margin-bottom: 0 !important;
  }

  .react-autosuggest__input {
    min-width: 100% !important;
    width: 100%;
  }

  .search-wrapper {
    margin-right: 0;
  }
}
