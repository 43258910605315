.plus_btn {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  background-color: #ffffff;
  padding: 10px;
  margin: -5px -10px -5px 0;
  border: 0;
  border-radius: 50%;

  & svg:hover g > path {
    fill: #464288;
  }

  & > svg {
    width: 26px;
    min-width: 26px;
    height: 28px;
  }
}

.minus_btn {
  background-color: #5954a8;
  width: 26px;
  min-width: 26px;
  height: 26px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 0;
  margin: 6px 0 6px 10px;

  &:hover {
    background-color: #464288;
  }

  & > svg {
    width: 18px;
    fill: #ffffff;
  }
}
