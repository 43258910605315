.company-roles-table {
  table.list-general {
    min-width: 400px;

    th {
      width: 100%;
    }

    .th-buttons {
      width: 120px;
    }
  }
}
