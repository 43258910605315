@mixin widthAndHeight($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin resetButton() {
  background: none;
  border: none;
  cursor: pointer;
}

@mixin center() {
  display: flex;
  align-content: center;
  justify-content: center;
}

// spinner styles which are used in autosuggests
@mixin loadingSpinner {
  position: relative;

  &:after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 10px;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid $photo-preview-loading;
    border-right: 2px solid transparent;
    animation: spinner 0.6s linear infinite;
  }
}

@mixin table-row-variant($state, $background, $hover-background, $border: null) {
  .table-#{$state} {
    &,
    > th,
    > td {
      background-color: $background;
    }

    @if $border != null {
      th,
      td,
      thead th,
      tbody + tbody {
        border-color: $border;
      }
    }
  }

  .table-hover {
    .table-#{$state} {
      &:hover {
        background-color: $hover-background;

        > td,
        > th {
          background-color: $hover-background;
        }
      }
    }
  }
}
