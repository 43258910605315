.loading {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 1;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.2s;
  z-index: 10;

  &.loading--bg {
    background: rgba(255, 255, 255, 0.4);
  }

  &.loading--fixed {
    position: fixed;
  }
}
.input-loader {
  display: flex;
  justify-content: center;
  height: 100%;

  .loading {
    position: static;
    width: 20px;
    display: inline-flex;
  }
}

.small-loader {
  display: inline-flex;

  .loading {
    position: static;
    width: 20px;
    display: inline-flex;
  }
}
