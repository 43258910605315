@import 'src/shared/variables';

.entity-header__logs {
  @include media-breakpoint-down(sm) {
    display: none;
  }

  @media (min-width: $screen-sm) and (max-width: $screen-lg - 1) {
    padding: 0;
    order: -1;
    margin-top: -10px;
  }

  @media (min-width: $screen-lg) {
    position: absolute;
    top: 20px;
    right: 25px;
    text-align: right;
    margin: 0;
  }

  color: $entity-header-logs;
  font-size: 10px;
  margin: 15px 0;
  padding: 0;
}

.entity-header__employee-position {
  font-size: 12px;
  line-height: 20px;
  font-weight: 600;
  margin-top: 8px;
}

.entity-header__employee-email {
  margin-top: 4px;
}
