@import 'shared/variables';

.react-autosuggest__suggestions-container {
  transform: translateY(9px);
}

.filter-input.autosuggest-button-dropdown {
  .react-autosuggest__warning-message {
    border-top: 1px solid $filter-search-warning-border;
    border-bottom: 1px solid $filter-search-warning-border;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 5px;
    background: var(--mui-pallete-background-paper);
  }

  .react-autosuggest__no-result,
  .react-autosuggest__suggestions-container {
    position: static;
    border: 0;
    box-shadow: none;
    transform: none;
    background: var(--mui-pallete-background-paper);
  }

  .search-filter-control-wrapper {
    .react-autosuggest-filter--loader {
      position: absolute;
      top: 11px;
      right: 16px;
    }
  }

  .search-filter-control {
    background: none;
    border: 0;
    padding: 8px 15px 8px 42px;
    width: 100%;
    line-height: 1.25;
    min-height: 38px;

    @media (max-width: $screen-sm-max) {
      font-size: 14px;
    }

    &:focus {
      box-shadow: none;
      outline: none;
    }

    &.search-filter-control--loading {
      padding-right: 45px;
    }
  }
}
