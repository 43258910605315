.single-entry-info-block {
  .balances-list.table-wrapper {
    min-width: 1160px;

    th {
      white-space: normal;
    }

    tbody {
      td {
        vertical-align: top;

        &:nth-child(-n + 2) {
          font-weight: bold;
        }
      }
    }

    .balances-leaves-column {
      p {
        position: relative;
        min-height: 40px;
      }
    }

    .balances-leaves-type-badges-container {
      position: absolute;
      bottom: 4px;
    }

    .MuiChip-root:first-child {
      margin-left: 0;
    }
  }
}
