@import 'shared/variables';

$self: '.files-list';

#{$self} {
  &__files {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: flex;
      border-radius: 3px;
      border: 1px solid $files-list-border;
      margin: 6px 12px 6px 0;
      height: 35px;
      max-width: 100%;

      @media (max-width: $screen-md) {
        height: 30px;
        margin: 4px 8px 4px 0;
      }
    }

    button {
      background: none;
      cursor: pointer;
      padding: 0;
      align-items: center;
      border: none;
      display: flex;
      text-align: left;
      overflow: hidden;
      transition: all 0.15s;

      &[disabled] {
        opacity: 0.5;
        cursor: default;
      }

      &:first-child {
        padding: 6px;

        img {
          font-size: 0;
        }

        @media (max-width: $screen-md) {
          padding: 4px;
        }
      }

      &:last-child {
        border-left: 1px solid $files-list-border;
        min-width: 30px;
        justify-content: center;
      }

      &:hover {
        background-color: $employee-list-row-hover-bg;
      }

      &:focus {
        outline: none;
      }
    }

    span {
      color: $files-list-txt;
      font-size: 0.875rem;
    }

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &-icon {
      height: 30px;
      margin-right: -6px;
      width: 34px;
      margin-left: -8px;
      flex: 1 0 28px;
      min-width: 34px;
    }

    &-image {
      width: 25px;
      height: 22px;
      margin: 0 3px 2px 2px;
      min-width: 25px;
    }

    &-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 0 0px 0 2px;
      max-width: 500px;
    }

    &-type {
      margin-right: 4px;
      flex-shrink: 0;
    }

    &-download {
      height: 14px;
      width: 11px;

      @media (max-width: $screen-md) {
        height: 13px;
        width: 10px;
      }

      img {
        margin-bottom: 3px;
        vertical-align: baseline;
      }
    }
  }

  &__file-link {
    span {
      color: #20a8d8;

      &:hover {
        color: #167495;
        text-decoration: underline;
      }
    }
  }

  &--multiline {
    flex-wrap: wrap;
  }

  &__photos {
    padding: 0;
    margin-bottom: -7px;

    li {
      display: inline-block;
      margin: 0 10px 10px 0;
    }

    button {
      border: 1px solid $files-list-border;
      height: 80px;
      width: 80px;
      padding: 0;

      &:last-child {
        display: none;
      }
    }

    span {
      overflow: hidden;
      height: 100%;
      width: 100%;
      margin: 0;

      &:not(:first-child) {
        display: none;
      }
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
}
