.passport-number {
  display: inline-block;
  width: 70%;
}

.passport-series {
  display: inline-block;
  width: calc(30% - 20px);
  margin-right: 20px;
  white-space: nowrap;
}
