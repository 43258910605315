@import 'shared/variables';
@import 'shared/mixins';

.collapse-wrapper:has(.applications-collapse-title-wrapper) {
  .applications-collapse-title-wrapper {
    flex-wrap: nowrap;
    padding-right: 50px;
  }

  .collapse-header-wrapper {
    padding: 8px 0 8px 6px;

    h3 {
      font-size: 14px;
    }
  }

  &.collapse-wrapper--open .collapse-header-wrapper .collapse-title-wrapper {
    padding-top: 0.5rem;
  }

  .collapse-title {
    padding-top: 0;
    width: auto;
  }
}

.applications-table-wrapper table {
  th {
    white-space: normal;

    &:first-of-type {
      width: auto;
    }

    &:last-child {
      width: 90px;
    }
  }

  td {
    &:first-of-type {
      padding-right: 0;
      padding-left: 3px;
    }
  }

  tbody td,
  th {
    padding-left: 10px;
    padding-right: 10px;
  }
}
