.stepper {
  .MuiStepConnector-root {
    margin-left: 17px;
  }

  .MuiStepContent-root {
    margin-left: 17px;
    border-left-color: var(--mui-palette-neutral-50);
    padding-left: 16px;
  }

  .MuiStepConnector-line {
    min-height: 32px;
    border-color: var(--mui-palette-neutral-50);
  }

  .MuiStepLabel-root {
    padding: 0;
  }

  .MuiStepLabel-label {
    font-weight: 400;
    font-size: 15px;
  }

  .MuiStepLabel-iconContainer {
    width: 35px;
    height: 35px;
    background: var(--mui-palette-primary-90);
    padding: 0;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    font-size: 12px;
    font-weight: 600;

    svg {
      font-size: 18px;
      transform: translateY(2px);
    }

    &.Mui-disabled {
      background-color: #201a1b1f;

      > div {
        opacity: 0.32;
      }
    }
  }

  .MuiSvgIcon-root {
    fill: var(--mui-palette-secondary-10);
  }

  .MuiStepConnector-root {
    cursor: default;
  }

  &__back-button {
    font-size: 12px;
    padding: 0;
    min-width: auto;
  }

  &__next-button {
    margin-right: 8px;
    font-size: 12px;

    &.Mui-disabled {
      cursor: not-allowed;
      pointer-events: initial;
    }
  }

  &__buttons-wrapper {
    margin-top: 8px;
    display: flex;
    align-items: center;
    padding-left: 4px;
  }
}
