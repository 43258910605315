@import 'shared/variables';

.horizontal-menu-container {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;

  .horizontal-menu-btn {
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    transform: translateY(-6px);
  }

  .horizontal-menu-btn--active {
    color: $horizontal-menu-active-item-text;
    pointer-events: none;
    background-color: $horizontal-menu-active-item-bg;
  }
}
