@import 'shared/variables';

body {
  .education-list-input {
    .removable-input {
      display: block;
      padding-bottom: 20px;
      border-bottom: 1px solid var(--mui-palette-primary-95);
      margin-bottom: 20px;

      .input-list-rm-btn {
        margin: 0 0 25px;
      }

      .education-document__wrapper > button:last-child,
      .education-list__document-scan .MuiFormControl-root {
        margin-bottom: 0;
      }
    }
  }

  .education-document__wrapper {
    > button:last-of-type {
      margin-bottom: 20px;
    }
  }
}
