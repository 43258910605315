.languages__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 3px;
}

.CandidateNativeLang {
  padding: 2px 8px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: #ffffff;
  border-radius: 3px;
  min-width: 32px;
  white-space: nowrap;
  text-transform: uppercase;
  background-color: #5954a8;
}
