$open-sans: 'Open Sans';

@font-face {
  font-family: $open-sans;
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url('./OpenSans-Regular.woff2') format('woff2'),
    url('./OpenSans-Regular.woff') format('woff'), url('./OpenSans-Regular.ttf') format('ttf');
}

@font-face {
  font-family: $open-sans;
  font-style: normal;
  font-weight: 500;
  src: local('Open Sans Medium'), local('OpenSansMedium'), url('./OpenSans-Medium.woff2') format('woff2'),
    url('./OpenSans-Medium.woff') format('woff'), url('./OpenSans-Medium.ttf') format('ttf');
}

@font-face {
  font-family: $open-sans;
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans Siem Bold'), local('OpenSansSemiBold'), url('./OpenSans-SemiBold.woff2') format('woff2'),
    url('./OpenSans-SemiBold.woff') format('woff'), url('./OpenSans-SemiBold.ttf') format('ttf');
}
