@import 'shared/variables';

.handbook-page__wrapper {
  padding-bottom: 20px;
  min-height: 300px;

  @media (min-width: 992px) {
    display: flex;
  }

  .handbook-menu__wrapper:not(.handbook-menu__wrapper--loading) {
    @media (min-width: 992px) {
      width: 285px;
      flex-shrink: 0;
      margin-right: 45px;
    }
  }
}

.handbook-content__inner {
  width: 100%;
  min-height: 200px;

  @media (min-width: 992px) {
    max-width: calc(100% - 330px);
  }
}

.handbook-content-wrapper {
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: var(--mui-palette-primary-20);
    margin-top: 0;
    margin-bottom: 25px;
  }

  p {
    + h1,
    + h2,
    + h3,
    + h4,
    + h5 {
      margin-top: 42px;
    }
  }

  p + ul {
    margin-top: -7px;
  }

  ul {
    p {
      margin-bottom: 0;

      + ul {
        margin-top: 8px;
      }
    }

    li {
      margin-bottom: 8px;
    }

    + h1,
    + h2,
    + h3,
    + h4,
    + h5 {
      margin-top: 46px;
    }
  }

  b,
  strong {
    font-weight: 600;
  }

  code {
    padding: 2px 4px;
    color: $gray-900 !important;
    background-color: $c-gray1;
    border-radius: 4px;
  }

  .table-wrapper {
    margin-bottom: $spacer;

    @media (max-width: $screen-xxl - 1) {
      overflow-x: scroll;
      overflow-y: visible;
    }
  }

  table {
    @media (max-width: $screen-xxl - 1) {
      min-width: 1480px;
    }

    margin-top: 12px;
    color: $table-color;
    background-color: $table-bg;
    margin-bottom: 30px;
    width: 100%;

    thead th {
      font-weight: 500;
      display: table-cell;
      vertical-align: inherit;
      border-bottom: 1px solid var(--mui-palette-TableCell-border);
      text-align: left;
      padding: 12px;
      color: var(--mui-palette-primary-20);
      font-size: 12px;
      line-height: 1.5;
    }

    td {
      font-weight: 400;
      display: table-cell;
      vertical-align: inherit;
      border-bottom: 1px solid var(--mui-palette-TableCell-border);
      text-align: left;
      color: var(--mui-palette-text-primary);
      font-size: 14px;
      line-height: 1.6;
      padding: 12px;
    }

    tbody tr {
      &:hover {
        background-color: #f1f1f1;
      }
    }
  }

  img {
    max-width: 100%;
  }

  > :last-child {
    margin-bottom: 0;
  }

  p {
    color: $gray-900;
    margin: 0 0 16px;

    > code {
      font-weight: inherit;
    }
  }

  blockquote,
  .blockquote {
    margin: 22px 31px 35px 23px;
    border: 2px solid $handbook-quote-border;
    padding: 22px 30px 24px;
    box-shadow: 11px 11px 0 0 $handbook-quote-border;

    br {
      content: '';
      margin-bottom: 16px;
      display: block;
    }

    & p:last-child {
      margin: 0;
    }

    + h1,
    + h2,
    + h3,
    + h4,
    + h5 {
      margin-top: 60px;
    }
  }

  hr {
    margin: 25px 0;
  }

  pre {
    margin-bottom: 16px;
    font-size: 13px;
    line-height: 1.6em;
    overflow-x: auto;
    border-radius: 2px;
  }

  ul,
  ol {
    padding: 0;
    margin: 0 0 16px;
  }

  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0;
  }

  > ul {
    list-style-type: disc;
  }

  > ul ul {
    list-style-type: circle;
  }

  > ul ul ul {
    list-style-type: square;
  }

  li {
    margin-left: 25px;
    padding-left: 3px;
  }

  .big {
    font-size: larger;
  }

  .small {
    font-size: smaller;
  }

  .underline {
    text-decoration: underline;
  }

  .overline {
    text-decoration: overline;
  }

  .line-through {
    text-decoration: line-through;
  }
}
