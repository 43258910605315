@import 'shared/variables';

.activity-row {
  td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

.ended-activity-item {
  color: $c-green1;
}

.incident-item {
  color: $c-red;
}

.activity-link {
  padding: 0 !important;
  user-select: auto !important;
  margin-right: 5px;
}
