@import 'src/shared/variables';

.notification-checkbox {
  margin-top: 11px;

  @media (max-width: $screen-md-max) {
    margin-top: 0;
    .form-group {
      margin-bottom: 10px;
    }
  }
}
