@import 'shared/variables';

.onestop-categories-container {
  display: flex;

  .onestop-requests-container {
    flex-grow: 1;
    list-style: none;
    padding: 18px 0;

    @media (max-width: 991px) {
      padding: 25px 50px;
    }

    @media (max-width: $screen-sm-max) {
      padding: 25px;
    }
  }
}

.onestop-categories {
  flex-direction: column;
  padding: 0;
  width: 100%;
  flex: 0 0 auto;

  @media (min-width: 992px) {
    width: 270px;
  }

  @media (max-width: 991px) {
    margin-top: 0;
  }

  &.nav-tabs {
    border: none;
    list-style: none;

    @media (min-width: 992px) {
      &:not(:empty) {
        border-right: 1px solid #000;
        margin-right: 35px;
        padding-right: 20px;
      }
    }

    .nav-link {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 14px;
      min-height: 32px;
      position: relative;
      border: none;
      border-radius: 0;
      background-color: transparent;
      width: 100%;
      text-align: left;
      outline: none;
      padding: 10px 16px;
      font-family: inherit;
      cursor: pointer;

      &:hover {
        background: rgba(80, 67, 73, 0.08);
      }

      &.active,
      &.active:focus {
        background: $onestop-category-active-bg;
      }

      &.text-info {
        color: var(--mui-palette-primary-main);
      }

      .MuiSvgIcon-root {
        margin-right: 7px;
        width: 21px;
        height: 21px;
      }
    }
  }
}

.onestop-request {
  &:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid var(--mui-palette-common-separator);
  }
}

.onestop-request__header {
  font-size: 18px;
}

.onestop-request__desc {
  margin: 6px 0 0;
  color: #817379;
}

.onestop-request__date {
  color: #817379;
  font-size: 12px;
  margin-top: 5px;
  font-weight: 600;
  display: block;
  padding: 0;
}

.onestop-search-item {
  color: inherit;
  display: block;
  padding: 15px 16px;
  margin-bottom: 12px;

  &:hover,
  &:focus,
  &.focus {
    background-color: $onestop-search-item-active-color;
    color: inherit;
    outline: none;
    text-decoration: none;
  }
}

.onestop-search-no-results {
  margin-top: 30px;
  text-align: center;
  font-weight: 400;
}

.onestop-popup-content {
  text-align: center;
}

.onestop-search-wrapper {
  @media (max-width: 991px) {
    margin-bottom: 0;
  }

  .react-autosuggest__input {
    min-width: 100% !important;
    width: 100%;
  }
}
