.icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mui-palette-primary-40);
  border-radius: 50%;
  color: white;
  margin-left: -2px;
  padding: 6px;

  svg {
    font-size: 18px;
  }

  &.highlighted {
    background-color: var(--mui-palette-primary-20);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    margin-left: -5px;
    padding: 8px;

    svg {
      font-size: 20px;
    }
  }
}

.system_notes_icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--mui-palette-neutral-40);
  border-radius: 50%;
  color: white;
  margin-left: -2px;
  padding: 6px;

  svg {
    font-size: 18px;
  }

  &.highlighted {
    background-color: var(--mui-palette-neutral-20);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    margin-left: -7px;
    padding: 10px;

    svg {
      font-size: 22px;
    }
  }
}

.content_container {
  &.highlighted {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  }
}

.step_content_container {
  padding: 6px 0 28px 26px;

  blockquote {
    font-style: italic;
    padding: 11px 25px 11px 17px;
    margin: 0 0 20px;
    transform: translateY(10px);
  }
}
