@import 'shared/variables';

.modal-text-form-btn {
  .form-control {
    text-align: left !important;
    vertical-align: middle;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 39px;
    max-width: 100%;
    cursor: pointer;
  }

  &.disabled-btn {
    .form-control {
      cursor: pointer;
      border-color: var(--mui-palette-common-commonInputBorder);
      opacity: 0.38;
      background-color: var(--mui-palette-common-inputDisabledBackground);
      box-shadow: none;
    }
  }

  &.error-section {
    .form-control {
      border-color: var(--mui-palette-error-main);
    }
  }
}
